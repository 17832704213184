<template>
    <div class="w-full h-full px-[1rem] py-[1rem] bg-white font-NotoSansKR" id="SUGEUM_view">
        <div v-if="type!='info'" class="flex flex-row justify-between items-center w-full mb-[1rem]">
            <p class="text-[2.2rem] leading-[2.6rem] font-semibold text-[#2b3133] tracking-tighter">{{ type == 'info' ? '수금현황' : '수금관리' }}</p>
            <div @click="onClickSave" class="w-[13rem] h-[3rem] text-center select-none text-white bg-[#006EDD] rounded-lg cursor-pointer flex flex-row items-center justify-center">저장</div>
        </div>
        <SelectButton v-if="type=='info'" v-model="tabType" :options="[{ value: '실적현황' },{ value: '프로젝트 리스트' }]" class="flex text-center mb-[2rem] justify-around items-center w-[27.6rem] h-[4rem] bg-[#f8f8f8] rounded-lg p-[4px]" optionLabel="value" dataKey="value" aria-labelledby="custom">
            <template #option="slotProps">
                <p class="cursor-pointer select-none text-[1.1rem] rounded-lg w-[13.2rem] text-center py-[0.9rem] h-[3.2rem]]">{{ slotProps.option.value }}</p>
            </template>
        </SelectButton>
        <div v-if="type=='info'" class="flex flex-row items-center justify-between w-full ">
            <div class="flex flex-row items-center mb-[1rem] pl-[0rem] pb-0 ">
                <p class="mr-[1rem] text-[#92959B] text-[1.3rem]">조회연도</p>
                <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
                    <option v-for="y in yearList" :key="y">{{ y }}</option>
                </select>
                <p v-if="tabType.value == '프로젝트 리스트'" class="mx-[1rem] text-[#92959B] text-[1.3rem]">진행상태</p>
                <select v-if="tabType.value == '프로젝트 리스트'" class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7] mx-[1rem]" v-model="procType">
                    <option v-for="pt in procTypes" :key="pt">{{ pt }}</option>
                    </select>
                <p v-if="tabType.value == '프로젝트 리스트'" class="mx-[1rem] text-[#92959B] text-[1.3rem]">본부별</p>
                <select v-if="tabType.value == '프로젝트 리스트'" class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="bonbu">
                    <option>전체</option>
                    <option v-for="bonbu in bonbuList" :key="bonbu">{{ bonbu.BUSEOMYEONG_NM }}</option>
                </select>
            </div>
            <div class="flex flex-row items-center">
                <div v-if="tabType.value == '프로젝트 리스트'" @click="onClickColChooser" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-white border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer">컬럼 조정</div> 
                <div @click="onClickExcelDown" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-2 rounded-lg w-[10rem] text-center py-[0.4rem] mr-[1rem]">엑셀 다운로드</div>
                <input ref="excelInput" class="hidden" type="file" @input="onInputExcelLoad" accept=".xlsx, .xlsm, .xltx, .xltm, .xls, .xlt">
                <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </div>
            </div>
        </div>
        <div class="w-full"></div>
        <BarLineChart_SG :year="year" v-if="type=='info' && tabType.value != '프로젝트 리스트'"/>
        <div v-if="type!='info'" class="flex flex-row items-centerw-full">
            <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
                <option v-for="y in yearList" :key="y">{{ y }}</option>
            </select>
            <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7] mx-[1rem]" v-model="procType">
                <option v-for="pt in procTypes" :key="pt">{{ pt }}</option>
            </select>
            <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="bonbu">
                <option>전체</option>
                <option v-for="bonbu in bonbuList" :key="bonbu">{{ bonbu.BUSEOMYEONG_NM }}</option>
            </select>

            <SelectButton v-model="viewType" :options="[{ value: '전체' },{ value: '예상' },{ value: '확정' }]" class="text-center mx-[1rem]" optionLabel="value" dataKey="value" aria-labelledby="custom">
                <template #option="slotProps">
                    <p class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg w-[6rem] text-center py-[0.9rem] px-[1rem]">{{ slotProps.option.value }}</p>
                </template>
            </SelectButton>
            <div class="flex flex-row items-center">
                <input @keydown="searchKeyDown" type="text" v-model="keyword" placeholder="PJ코드, PJ명, 발주처 검색" class="border-[1px] px-[1rem] rounded-lg h-[3.7rem] w-[50rem]">
                <div @click="searchCallback" class="flex flex-row items-center justify-center ml-[2rem] rounded-lg w-[11rem] h-[3.7rem] bg-[#787878] select-none cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#fff" class="w-[1.6rem] h-[2rem] mr-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                    <p class="text-white">검색</p>
                </div>
                <div @click="projectSearch.show" class="flex flex-row items-center justify-center ml-[2rem] rounded-lg w-[18rem] h-[3.7rem] bg-[#787878] select-none cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#fff" class="w-[1.6rem] h-[2rem] mr-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                    <p class="text-white">전체 프로젝트 검색</p>
                </div>
            </div>
        </div>
        <div v-if="type!='info'" class="h-8"></div>
        <div v-if="type!='info'" class="flex flex-row justify-between text-lg w-full">
                <div class="flex flex-row items-center">
                    <div @click="openPms" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg w-[10rem] text-center py-[0.4rem] mr-[1rem]">PMS 정보보기</div>
                    <div @click="historySidebar.open()" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg w-[10rem] text-center py-[0.4rem]">변경 조회</div>
                </div>
                <div class="flex flex-row items-center gap-[1rem]">
                    <!-- <div v-if="type!='info'" @click="onClickExcelDown" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-2 rounded-lg w-[10rem] text-center py-[0.4rem] mr-[1rem]">엑셀 다운로드</div>
                    <input ref="excelInput" class="hidden" type="file" @input="onInputExcelLoad" accept=".xlsx, .xlsm, .xltx, .xltm, .xls, .xlt">
                    <div v-if="type!='info'" @click="onClickExcelLoad" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-2 rounded-lg w-[10rem] text-center py-[0.4rem] mr-[1rem]">엑셀 불러오기</div> -->
                    <div @click="onClickColChooser" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg w-[10rem] text-center py-[0.4rem]">컬럼 조정</div>
                    <div v-if="type!='info'" @click="onClickEdit" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg w-[10rem] text-center py-[0.4rem]">상세 편집</div>
                    <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </div>
                </div>
        </div>
        <div v-if="type=='info' && tabType.value == '프로젝트 리스트'" class="flex flex-row justify-end text-gray-600 text-[1rem]">( 단위: 백만원 )</div>
        <div v-if="type!='info'" class="flex flex-row justify-end text-gray-600 text-[1rem] mt-[1rem]">( 단위: 원 )</div>

        <!-- 240329 w, h값 수정 :height="`calc(100% - ${this.type == 'info' ? 0 : 2}rem)`" -->
        <div :class="hiddenGrid()" class="flex flex-row w-full h-[calc(100%-15rem)]" id="hiddenGrid" >
            <!-- 240329 w, h값 수정 -->
            <div class="flex flex-col h-full w-full">
                <DxDataGrid v-if="type=='info'" id="dataGridInfo" ref="dataGrid1" class="w-full select-none" :height="`calc(100% - ${this.type == 'info' ? 0 : 1}rem)`" :columns="columns" column-resizing-mode="widget"
                @cell-prepared="onCellPrepared($event)" @cell-hover-changed="onCellHoverChanged($event)" :allow-column-resizing="true" :allow-column-reordering="true"
                @content-ready="onContentReady()" @cellClick="onCellClick($event)" @option-changed="onOptionChanged"
                @editingStart="onEditStart($event)" @row-prepared="onRowPrePared($event)" :data-source="dataSource" key-expr="SUJU_SQ" :show-borders="true">
                <DxColumnFixing :enabled="true"/>
                <DxColumnChooser title="컬럼 편집" empty-panel-text="숨길 컬럼을 이 영역으로 드래그" :enabled="columFlag">
                <DxPosition my="right bottom" at="right bottom" of="dataGrid"></DxPosition>
            </DxColumnChooser>   
                <DxPaging :enabled="false"/>
                    <DxSelection mode="single"/>
                    <DxHeaderFilter :visible="true"/>
                    <DxScrolling mode="'virtual" useNative="true" ScrollbarMode="always"/> 
                    <DxLoadPanel :enabled="true"/>
                    <DxEditing startEditAction="dblClick" mode="cell" :allow-updating="this.type!='info'" :allow-adding="this.type!='info'" :allow-deleting="false"></DxEditing>
                    <DxKeyboardNavigation
                        :edit-on-key-press="true"
                        enter-key-action="column"
                        enter-key-direction="moveFocus"
                    />
                    <template #month-cell-template="{ data: data }">
                        <div :class="monthCellClass(data) + ' h-full p-0 bg-transparent'">
                            <div class="relative top-[-12px]">
                                <div v-if="getMonthBigo(data)" @click="onClickEdit($event,data)" class="absolute bg-red-600 h-3 w-4 left-[-0.5rem]"></div>
                            </div>
                            <div class="flex flex-row h-full w-auto items-center bg-transparent">
                                <div v-if="data.column.dataField.toString().includes(nowMonth.toString()) && viewType.value === '전체'" class="w-[12rem] bg-[#f8f8f8] leading-[5rem] h-full border-r-2 pr-2 ml-[-2rem]">{{ monthCellValue(data,'TODAY_SUGEUM_MONTH_CN_S0') }}</div>
                                <div class="bg-transparent w-[10rem] leading-[5rem] h-full">{{ monthCellValue(data,data.column.dataField) }}</div>
                            </div>
                        </div>
                    </template>
                    <DxSummary>
                        <DxTotalItem :customize-text="acountView" v-for="(index, monthIndex) in 12" :key="monthIndex" display-format="{0}" value-format="###,##0" :column="`SUGEUM_MONTH${index}_CN`" summary-type="sum"/>
                        <DxTotalItem :customize-text="acountView" display-format="{0}" value-format="#,##0" :column="`합계`" summary-type="sum"/>
                        <DxTotalItem :customize-text="acountView" display-format="{0}" value-format="#,##0" :column="`잔금`" summary-type="sum"/>
                        <DxTotalItem :customize-text="acountView" display-format="{0}" value-format="#,##0" :column="`이월`" summary-type="sum"/>
                        <DxTotalItem :customize-text="acountView" display-format="{0}" value-format="#,##0" :column="`GYEYAK_GEUMAEK_AM`" summary-type="sum"/>
                        <DxTotalItem :customize-text="acountView" display-format="{0}" value-format="#,##0" :column="`GONGDONG`" summary-type="sum"/>
                    </DxSummary>
                </DxDataGrid>
                <DxDataGrid v-if="type!='info'" id="dataGrid" ref="dataGrid2" class="w-full select-none" :height="`calc(100% - ${this.type == 'info' ? 0 : 1}rem)`" :columns="columns" column-resizing-mode="widget"
                    @cell-prepared="onCellPrepared($event)" @cell-hover-changed="onCellHoverChanged($event)" :allow-column-resizing="true" :allow-column-reordering="true"
                    @content-ready="onContentReady()" @cellClick="onCellClick($event)" @option-changed="onOptionChanged"
                    @editingStart="onEditStart($event)" @row-prepared="onRowPrePared($event)" :data-source="dataSource" key-expr="SUJU_SQ" :show-borders="true">
                    <DxColumnFixing :enabled="true"/>
                    <DxColumnChooser title="컬럼 편집" empty-panel-text="숨길 컬럼을 이 영역으로 드래그" :enabled="columFlag">
                    <DxPosition my="right bottom" at="right bottom" of="dataGrid"></DxPosition>
                    </DxColumnChooser>   
                    <DxPaging :enabled="false"/>
                    <DxSelection mode="single"/>
                    <DxHeaderFilter :visible="true"/>
                    <DxScrolling mode="'virtual" useNative="true" ScrollbarMode="always"/> 
                    <DxLoadPanel :enabled="true"/>
                    <DxEditing startEditAction="dblClick" mode="cell" :allow-updating="this.type!='info'" :allow-adding="this.type!='info'" :allow-deleting="false"></DxEditing>
                    <DxKeyboardNavigation
                        :edit-on-key-press="true"
                        enter-key-action="column"
                        enter-key-direction="moveFocus"
                    />
                    <template #month-cell-template="{ data: data }">
                        <div :class="monthCellClass(data) + ' h-full p-0 bg-transparent'">
                            <div class="relative top-[-12px]">
                                <div v-if="getMonthBigo(data)" @click="onClickEdit($event,data)" class="absolute bg-red-600 h-3 w-4 left-[-0.5rem]"></div>
                            </div>
                            <div class="flex flex-row h-full w-auto items-center bg-transparent">
                                <div v-if="data.column.dataField.toString().includes(nowMonth.toString()) && viewType.value === '전체'" class="w-[12rem] bg-[#f8f8f8] leading-[5rem] h-full border-r-2 pr-2 ml-[-2rem]">{{ monthCellValue(data,'TODAY_SUGEUM_MONTH_CN_S0') }}</div>
                                <div class="bg-transparent w-[10rem] leading-[5rem] h-full">{{ monthCellValue(data,data.column.dataField) }}</div>
                            </div>
                        </div>
                    </template>
                    <DxSummary>
                        <DxTotalItem :customize-text="acountView" v-for="(index, monthIndex) in 12" :key="monthIndex" display-format="{0}" value-format="###,##0" :column="`SUGEUM_MONTH${index}_CN`" summary-type="sum"/>
                        <DxTotalItem :customize-text="acountView" display-format="{0}" value-format="#,##0" :column="`합계`" summary-type="sum"/>
                        <DxTotalItem :customize-text="acountView" display-format="{0}" value-format="#,##0" :column="`잔금`" summary-type="sum"/>
                        <DxTotalItem :customize-text="acountView" display-format="{0}" value-format="#,##0" :column="`이월`" summary-type="sum"/>
                        <DxTotalItem :customize-text="acountView" display-format="{0}" value-format="#,##0" :column="`GYEYAK_GEUMAEK_AM`" summary-type="sum"/>
                        <DxTotalItem :customize-text="acountView" display-format="{0}" value-format="#,##0" :column="`GONGDONG`" summary-type="sum"/>
                    </DxSummary>
                </DxDataGrid>
                <DxPopup title="상세편집" :visible="popupVisible" :width="480" :height="560">
                    <div class="flex flex-col w-full">
                        <div class="flex flex-row w-full justify-between">
                            <div class="flex flex-col w-full mb-[2.4rem]">
                                <div class="font-bold text-xl leading-[2.4rem]">예상금액</div>
                                <InputNumber class="border-2 w-[20rem] rounded-lg h-[3rem] pl-[.8rem]" v-model="formData.SUGEUM_WON_AM1" :maxFractionDigits="3"/>
                                <!-- <input class="border-2 w-[20rem] rounded-lg h-[3rem] pl-[.8rem]" v-model="formData.SUGEUM_WON_AM1" type="text"/> -->
                            </div>
                            <div class="flex flex-col w-full">
                                <div class="font-bold text-xl leading-[2.4rem]">금액확정</div>
                                <InputNumber class="border-2 w-[20rem] rounded-lg h-[3rem] pl-[.8rem]" v-model="formData.SUGEUM_WON_AM2" :maxFractionDigits="3"/>
                                <!-- <input class="border-2 w-[20rem] rounded-lg h-[3rem] pl-[.8rem]" v-model="formData.SUGEUM_WON_AM2" type="text"/> -->
                            </div>
                        </div>
                        <div class="flex flex-row w-full justify-between">
                            <div class="flex flex-col w-full">
                                <div class="font-bold text-xl leading-[2.4rem]">예상수금일</div>
                                <input v-if="type=='info'" readonly v-model="formData.SUGEUM_DATE_DT1" class="border-2 w-[20rem] rounded-lg h-[3rem] pl-[.8rem]" type="date"/>
                                <input v-if="type!='info'" v-model="formData.SUGEUM_DATE_DT1" class="border-2 w-[20rem] h-[3rem] pl-[.8rem] rounded-lg" type="date"/>
                            </div>
                            <div class="flex flex-col w-full">
                                <div class="font-bold text-xl leading-[2.4rem]">실제수금일</div>
                                <input v-if="type=='info'" readonly v-model="formData.SUGEUM_DATE_DT2" class="border-2 w-[20rem] h-[3rem] rounded-lg pl-[.8rem]" type="date"/>
                                <input v-if="type!='info'" v-model="formData.SUGEUM_DATE_DT2" class="border-2 w-[20rem] h-[3rem] rounded-lg pl-[.8rem]" type="date"/>
                            </div>
                        </div>
                        <div class="font-bold mt-[2.4rem] text-xl leading-[2.4rem]">변경사유</div>
                        <textarea v-if="type=='info'" readonly class="border-2 h-80 rounded-lg pl-[.8rem] pt-[.8rem]" v-model="formData.BIGO"></textarea>
                        <textarea v-if="type!='info'" class="border-2 h-80 rounded-lg pl-[.8rem] pt-[.8rem]" v-model="formData.BIGO"></textarea>
                        <div class="flex flex-row items-center justify-end absolute bottom-[2.4rem] right-[2.4rem]">
                            <div @click="()=>{this.popupVisible = false}" class="mr-[1.6rem] text-[1.2rem] bg-[#ffffff] border-[1px] cursor-pointer rounded-lg text-[#818181] py-[1rem] tracking-tighter w-[10rem] text-center hover:bg-slate-300 ml-[1.6rem]">닫기</div>
                            <div v-if="type!='info'" @click="onClickPopupSave" class="text-[1.2rem] bg-[#006EDD] cursor-pointer rounded-lg border-[#c7c7c7] text-[#ffffff] px-[2.4rem] py-[1rem] tracking-tighter w-[10rem] text-center hover:bg-slate-300">저장</div>
                        </div>
                    </div>
                </DxPopup>
            </div>
        </div>
        <HistorySidebar ref="historySidebar" menuName="C0020"/>
        <DxToast
        v-model:visible="toastVisible"
        v-model:message="toastMsg"
        v-model:type="toastType"
        :display-time="600"
        >
        <DxPosition my="top" at="top" offset="0 50"/>
        </DxToast>
        <SUGEUM_SearchPopup ref="projectSearch" :search="keyword" :callBack="allSearchCallback">

        </SUGEUM_SearchPopup>
    </div>
</template>
    
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxHeaderFilter,
  DxScrolling,
  DxSelection,
  DxColumnChooser,
  DxLoadPanel,DxSummary,DxTotalItem,
  DxKeyboardNavigation,
  DxColumnFixing
  } from 'devextreme-vue/data-grid';
import { DxPopup } from 'devextreme-vue/popup';
import { DxToast, DxPosition } from 'devextreme-vue/toast';
import RestHelper from '../Helpers/RestHelper';
import DataHelper from '../Helpers/DataHelper';
import ClipboardHelper from '../Helpers/ClipboardHelper';
import SugeumModel from '../Models/SugeumModel'
import LogHelper from '../Helpers/LogHelper';
import * as ExcelJS from 'exceljs';
import sf from 'sf'
import moment from 'moment'
import SelectButton from 'primevue/selectbutton';
import BarLineChart_SG from './Charts/BarLineChart_SG.vue'
import HistorySidebar from '../Controls/HistorySidebar.vue';
import InputNumber from 'primevue/inputnumber';
import SUGEUM_SearchPopup from '../Controls/SUGEUM_SearchPopup.vue';


export default {
    components :{
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxPopup,
        DxColumnChooser,
        DxHeaderFilter,
        DxScrolling,
        DxSelection,HistorySidebar,
        DxLoadPanel,BarLineChart_SG,
        DxKeyboardNavigation,SelectButton,
        DxSummary,DxTotalItem,
        DxToast, DxPosition, InputNumber,SUGEUM_SearchPopup
    },
    props:{
        type : String,
    },
    computed:{
        historySidebar()
        {
            return this.$refs.historySidebar;
        },
        projectSearch()
        {
            return this.$refs.projectSearch;
        },
        dataGrid()
        {
            if(this.type == 'info')
            {
                return this.$refs.dataGrid1?.instance;
            }
            return this.$refs.dataGrid2?.instance;
        },
        monthKey()
        {
            const ret = [];
            for(let i = 1; i<13; i++)
            {
                ret.push('SUGEUM_MONTH_CN'+i);
            }
            return ret;
        },
        nowMonth()
        {
            return (new Date()).getMonth()+1;
        },
        procTypes()
        {
            return ['전체','진행','완료','보류']
        },
        tdClass()
        {
            return 'text-start'
        },
        projectSearch()
        {
            return this.$refs.projectSearch;
        },        
    },
    data(){
        return{
            keyword : '',
            columns : [],
            yearList : [],
            year : null,
            s0List : [],
            s1List : [],
            dataSource : [],
            tempDataSource : [],
            popupVisible : false,
            editTarget : {},
            formData : {
                SUGEUM_DATE_DT1: '', 
                SUGEUM_DATE_DT2: '',
            },
            selectedRange: {},
            isSelectionStopped: true,
            cellInfos: [],
            data: [],
            isCtrlInput : false,
            bonbuList : [],
            bonbu : '전체',
            procType : '전체',
            preSumMap : new Map(), //예상 합계
            targetSumMap : new Map(), // 목표 합계
            viewType : { value: '전체' },
            selProject : '',
            tabType : { value: '실적현황' },
            toastVisible : false,
            toastMsg : "",
            toastType : "success", //'success' : 'error'
            runningWindows : [],
            selectedYear: new Date().getFullYear(), // 예시로 현재 연도
            selectedMonth: new Date().getMonth() + 1, // 예시로 현재 월
            columFlag : true
        }
    },
    watch:{
        async year()
        {
            if(this.year == null)return;
            await this.setDatasource();
        },
        bonbu()
        {
            this.setFilteredData()
        },
        procType()
        {
            this.setFilteredData()
        },
        viewType()
        {
            const type = this.viewType.value;
            if(type === '전체')
            {
                for(const data of this.dataSource)
                {
                    data.setMONTH_Data();
                }
            }
            else if(type === '예상')
            {
                for(const data of this.dataSource)
                {
                    data.setMONTH_Data_0();
                }
            }
            else if(type === '확정')
            {
                for(const data of this.dataSource)
                {
                    data.setMONTH_Data_1();
                }
            }
            this.$nextTick(()=>{
                this.dataGrid?.refresh();
            })
        },
        tabType(n,o)
        {
            if(n == null)
            {
                this.tabType = o;
            }
            this.$nextTick(()=>{
                this.dataGrid?.refresh();
            })
        }
    },
    async mounted(){
        await this.getYears();

        this.$nextTick(()=>{
            this.setCols();
        })
    },
    methods:{
        allSearchCallback(row)
        {
            this.keyword = row?.PJ_NM;
            this.year = row?.SUGEUM_YEAR_CN;
            this.selProject = this.keyword;
            this.setFilteredData();    
        },
        hiddenGrid()
        {
            if(this.type!='info' || this.tabType.value == '프로젝트 리스트')
            {
                return 'visible';
            }
            else
            {
                return 'hidden';
            }
        },
        searchKeyDown(e)
        {
            if(e.code == 'Enter' || e.code == 'NumpadEnter')
            {
                this.searchCallback();
            }
        },
        async init()
        {
            this.year = null;
            await this.getYears();         
        },
        searchCallback()
        {
            this.selProject = this.keyword;
            this.setFilteredData();    
        },
        onClickExcelLoad()
        {
            this.$refs.excelInput.value = ""
            this.$refs.excelInput.click();
        },
        async onInputExcelLoad(e)
        {
            const file = e.target.files[0];
            const reader = new FileReader();

            const setMonthValue = (data,month,value)=>{
                let s1  = data.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${month}_CN_S1`];
                if(isNaN(s1) || s1 == 0 || s1 == null)
                {
                    data[`SUGEUM_MONTH${month}_CN`] = value;
                }
                else
                {
                    data.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${month}_CN_S0`] = value;
                }
            }

            reader.onload = async (readerEvent) => {
                const fileContent = readerEvent.target.result;
                const workbook = new ExcelJS.Workbook();
                
                // 파일 데이터를 직접 사용하여 엑셀 워크북으로 읽기
                await workbook.xlsx.load(fileContent);
                const worksheet = workbook.getWorksheet(1);
                for(let i = 2; i<worksheet.rowCount+1; i++)
                {
                    const idx = worksheet.getCell('A'+i).value;
                    const data = this.dataSource.find(x=>x.SUJU_SQ === idx);
                    for(let month = 1; month<13; month++)
                    {
                        setMonthValue(data,month,worksheet.getCell('M'+i).value);
                    }
                }
                this.$nextTick(()=>{
                    this.dataGrid?.refresh();
                })
            }

            reader.readAsArrayBuffer(file);
        },
        async onClickExcelDown()
        {
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet("sheet name");
            const header = ['Idx','PJ CODE','수금부서','수주분류','업무분류','PJ명','발주처','합계','계약금액','기성누계','잔금','이월',]
            for(let i = 1; i<13; i++) { header.push(`${i}월`)}
            const headerRow = sheet.addRow(header)
            const headerWidths = [12,12,20,24,24,52,34,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20]
            headerRow.height = 30;
            headerRow.eachCell((cell,colNum)=>{
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "ffebebeb" },
                };
                cell.border = {
                    bottom: { style: "thin", color: { argb: "-100000f" } },
                    right: { style: "thin", color: { argb: "-100000f" } },
                };
                cell.font = {
                    name: "Arial",
                    size: 12,
                    bold: true,
                    color: { argb: "ff252525" },
                };
                sheet.getColumn(colNum).alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
                sheet.getColumn(colNum).width = headerWidths[colNum -1];
            })

            let rIdx = 2;
            for(const data of this.dataSource)
            {
                const rowdata = [
                    data.SUJU_SQ,
                    data.PJCODE_ID, data.BONBU_NM, data.BUNRYU_CD,data.EOPMU_CD,
                    data.PJ_NM, data.BALJU_NM, { formula: `SUM(M${rIdx}:X${rIdx})`},data.GYEYAK_GEUMAEK_AM,
                    data.GONGDONG, { formula: `I${rIdx}-J${rIdx}`}, { formula: `SUM(M${rIdx}:X${rIdx})`},
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH1_CN_S0 ?? 0, 
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH2_CN_S0 ?? 0,
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH3_CN_S0 ?? 0,
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH4_CN_S0 ?? 0, 
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH5_CN_S0 ?? 0,
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH6_CN_S0 ?? 0,
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH7_CN_S0 ?? 0, 
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH8_CN_S0 ?? 0,
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH9_CN_S0 ?? 0,
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH10_CN_S0 ?? 0, 
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH11_CN_S0 ?? 0,
                    data.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH12_CN_S0 ?? 0,]
                const appendRow = sheet.addRow(rowdata);
                appendRow.eachCell((cell,colNum)=>{
                    cell.border = {
                        bottom: { style: "thin", color: { argb: "-100000f" } },
                        right: { style: "thin", color: { argb: "-100000f" } },
                    };
                    if(colNum <= 12)
                    {
                        cell.protection = {
                            locked: true,
                            hidden: false,
                        };
                    }
                    else
                    {
                        cell.protection = {
                            locked: false,
                            hidden: false,
                        };
                    }
                })
                rIdx++;
            }
            sheet.autoFilter = 'A1:L1';
            await sheet.protect('the-password', { selectLockedCells : true});


            const fileData = await workbook.xlsx.writeBuffer();
            const blob = new Blob([fileData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",});
            const objectUrl = (window.webkitURL || window.URL).createObjectURL(blob);
            const link = window.document.createElement("a");
            link.href = objectUrl;
            link.download = `수금관리_${moment().format("YYYYMMDD")}.xlsx`;
            link.click();
        },
        onOptionChanged(e)
        {
            const pageName = this.type === 'info' ? '수금현황' : '수금관리';
            if(e.name != "columns")return;
            const visibleCols = e.component.getVisibleColumns().filter(x=>x.headId != undefined).map(x=>x.headId);
            this.$nextTick(async ()=>{
                const hideCols = this.columns.filter(x=>visibleCols.find(y=>y == x.headId) == null).map(x=>x.headId);
                
                let vString = '';
                let hString = '';

                for(const o of visibleCols)
                {
                    vString += o + ','
                }
                for(const o of hideCols)
                {
                    hString += o + ','
                }
                vString =  vString.slice(0,-1);
                hString =  hString.slice(0,-1);

                if(DataHelper.StringNullorEmpty(vString))
                {
                    return;
                }
                const res = await RestHelper.RestSend('USP_COLUMN','UPDATE',[{
                    PAGE_NM : pageName,
                    COLUMN_INDEX_NM : vString,
                    COLUMN_HIDE_NM : hString,
                }])
                if(res._result[0].RESULTCD == '1')
                {
                    LogHelper.logWrite('컬럼 변경 저장',[res],this.logUse);
                    console.log('컬럼 변경 저장',res)
                }
            })
        },
        rowText(cellInfo)
        {
            return `${this.dataSource.findIndex(x=>x.SUJU_SQ == cellInfo.value)+1}. ${this.dataSource.find(x=>x.SUJU_SQ == cellInfo.value).PJ_NM}`;
        },
        // No Column 넘버링 관련...
        numberingText(cellInfo)
        {
            return `${this.dataSource.findIndex(x => x.SUJU_SQ == cellInfo.value) + 1}`;
        },
        async setCols(){
            const pageName = this.type === 'info' ? '수금현황' : '수금관리';
            const standardCols = [
                {
                    headId : 1,
                    alignment : 'center',
                    caption : 'No',
                    width : '6rem',
                    dataField : 'SUJU_SQ',
                    allowEditing : false,
                    allowFiltering : false,
                    customizeText:this.numberingText,
                    cssClass : this.tdClass,
                    fixed:true,
                    cellTemplate: (container, options) => {
                        const data = options.data;

                        // 조건에 따라 배경색 설정
                        if (this.isCompleted(data?.GYEYAK_GEUMAEK_AM, data?.GONGDONG)) 
                        {
                            container.style.backgroundColor = '#EEF5F9'; // 완료
                        } else if (DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT)) 
                        {
                            container.style.color = '#aeaeae'; // 보류
                        }
                        container.innerText = options.text; 
                    }
                },
                {
                    headId : 2,
                    alignment : 'left',
                    caption : 'PJ NO',
                    width : '8rem',
                    dataField : 'PJCODE_ID',
                    allowEditing : false,
                    allowFiltering : false,
                    cssClass : this.tdClass,
                    fixed:true,
                    cellTemplate: (container, options) => {
                        const data = options.data;

                        // 조건에 따라 배경색 설정
                        if (this.isCompleted(data?.GYEYAK_GEUMAEK_AM, data?.GONGDONG)) 
                        {
                            container.style.backgroundColor = '#EEF5F9'; // 완료
                        } else if (DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT)) 
                        {
                            container.style.color = '#aeaeae'; // 보류
                        }
                        container.innerText = options.text; 
                    }
                },
                {
                    headId : 3,
                    alignment : 'left',
                    caption : '주관부서',
                    width : '12rem',
                    dataField : 'BONBU_NM',
                    allowEditing : false,
                    cssClass : this.tdClass,
                    fixed:true,
                    cellTemplate: (container, options) => {
                        const data = options.data;

                        // 조건에 따라 배경색 설정
                        if (this.isCompleted(data?.GYEYAK_GEUMAEK_AM, data?.GONGDONG)) 
                        {
                            container.style.backgroundColor = '#EEF5F9'; // 완료
                        } else if (DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT)) 
                        {
                            container.style.color = '#aeaeae'; // 보류
                        }
                        container.innerText = options.text; 
                    }
                },
                {
                    headId : 4,
                    alignment : 'left',
                    caption : '수주분류',
                    width : '12rem',
                    dataField : 'BUNRYU_CD',
                    allowEditing : false,
                    cssClass : this.tdClass,
                    fixed:true,
                    cellTemplate: (container, options) => {
                        const data = options.data;

                        // 조건에 따라 배경색 설정
                        if (this.isCompleted(data?.GYEYAK_GEUMAEK_AM, data?.GONGDONG)) 
                        {
                            container.style.backgroundColor = '#EEF5F9'; // 완료
                        } else if (DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT)) 
                        {
                            container.style.color = '#aeaeae'; // 보류
                        }
                        container.innerText = options.text; 
                    }
                },
                {
                    headId : 5,
                    alignment : 'left',
                    caption : '업무분류',
                    width : '12rem',
                    dataField : 'EOPMU_CD',
                    allowEditing : false,
                    cssClass : this.tdClass,
                    fixed:true,
                    cellTemplate: (container, options) => {
                        const data = options.data;

                        // 조건에 따라 배경색 설정
                        if (this.isCompleted(data?.GYEYAK_GEUMAEK_AM, data?.GONGDONG)) 
                        {
                            container.style.backgroundColor = '#EEF5F9'; // 완료
                        } else if (DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT)) 
                        {
                            container.style.color = '#aeaeae'; // 보류
                        }
                        container.innerText = options.text; 
                    }
                },
                {
                    headId : 6,
                    alignment : 'left',
                    caption : 'PJ명',
                    width : '20rem',
                    dataField : 'PJ_NM',
                    allowEditing : false,
                    cssClass : this.tdClass,
                    fixed:true,
                    cellTemplate: (container, options) => {
                        const data = options.data;

                        // 조건에 따라 배경색 설정
                        if (this.isCompleted(data?.GYEYAK_GEUMAEK_AM, data?.GONGDONG)) 
                        {
                            container.style.backgroundColor = '#EEF5F9'; // 완료
                        } else if (DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT)) 
                        {
                            container.style.color = '#aeaeae'; // 보류
                        }
                        container.innerText = options.text; 
                    }
                },
                {
                    headId : 7,
                    alignment : 'left',
                    caption : '발주처',
                    width : '14rem',
                    dataField : 'BALJU_NM',
                    allowEditing : false,
                    allowFiltering : false,
                    cssClass : this.tdClass,
                    fixed:true,
                    cellTemplate: (container, options) => 
                    {
                        const data = options.data;

                        // 조건에 따라 배경색 설정
                        if (this.isCompleted(data?.GYEYAK_GEUMAEK_AM, data?.GONGDONG)) 
                        {
                            container.style.backgroundColor = '#EEF5F9'; // 완료
                        } else if (DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT)) 
                        {
                            container.style.color = '#aeaeae'; // 보류
                        }
                        container.innerText = options.text; 
                    }
                },
                {
                    headId : 9,
                    alignment : 'right',
                    caption : '계약일',
                    width : '10rem',
                    dataField : 'GYEYAKIL_DT',
                    allowEditing : false,
                    allowFiltering : false,
                    cssClass : this.tdClass,
                    customizeText: this.setDt,
                    fixed:true,
                    cellTemplate: (container, options) => 
                    {
                        const data = options.data;

                        // 조건에 따라 배경색 설정
                        if (this.isCompleted(data?.GYEYAK_GEUMAEK_AM, data?.GONGDONG)) 
                        {
                            container.style.backgroundColor = '#EEF5F9'; // 완료
                        } else if (DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT)) 
                        {
                            container.style.color = '#aeaeae'; // 보류
                        }
                        container.innerText = options.text; 
                    }
                },
                {
                    headId : 9,
                    alignment : 'right',
                    caption : '계약금액',
                    width : '125px',
                    format : "###,##0",
                    dataField : 'GYEYAK_GEUMAEK_AM',
                    allowEditing : false,
                    allowFiltering : false,
                    cssClass : this.tdClass,
                    customizeText : this.acountView,
                },
                {
                    headId : 10,
                    alignment : 'center',
                    caption : '기성누계',
                    width : '115px',
                    format : "###,##0",
                    dataField : 'GONGDONG',
                    allowEditing : false,
                    allowFiltering : false,
                    cssClass : this.tdClass,
                    customizeText : this.acountView
                },
                {
                    headId : 11,
                    alignment : 'right',
                    caption : '잔금',
                    width : '125px',
                    format : "#,##0",
                    dataField : '100',
                    allowEditing : false,
                    allowFiltering : false,
                    calculateCellValue : this.calcJanGum,
                    cssClass : this.tdClass,
                    customizeText : this.acountView
                },
                {
                    headId : 8,
                    alignment : 'right',
                    caption : '당해년도 합계',
                    width : '115px',
                    format : "#,##0",
                    dataField : '합계',
                    allowEditing : false,
                    allowFiltering : false,
                    calculateCellValue:this.calcTotal,
                    cssClass : this.tdClass,
                    customizeText : this.acountView
                },
            ];
            for(let i = 1; i<13; i++)
            {
                standardCols.push({
                    headId : 12 + i,
                    alignment : 'right',
                    caption : `${i}월`,
                    width : 'auto',
                    format : "###,##0",
                    dataField : `SUGEUM_MONTH${i}_CN`,
                    allowEditing : 'false',
                    allowFiltering : 'false',
                    cellTemplate : 'month-cell-template',
                    customizeText : this.acountView
                })   
            }
            standardCols.push({
                headId : 12,
                alignment : 'right',
                caption : '이월금액',
                width : '125px',
                format : "#,##0",
                dataField : '이월',
                allowEditing : false,
                allowFiltering : false,
                calculateCellValue:this.calcIwual,
                cssClass : this.tdClass,
                customizeText : this.acountView
            })
            for(const i in standardCols)
            {
                standardCols[i].headId = Number(i)+1;
            }
            const res = await RestHelper.RestSend('USP_COLUMN','LIST',[{
                USER_ID : 1,
                PAGE_NM : pageName
            }])
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite('컬럼 리스트 요청',[res],this.logUse);
                const data = res._data[0];
                const sortList = [...new Set(data.COLUMN_INDEX_NM.split(','))];
                const hideList = [...new Set(data.COLUMN_HIDE_NM.split(','))];
                
                const useNum = [];
                const allNum = standardCols.map(x=>x.headId);
                this.columns = [];
                for(const o of sortList)
                {
                    if(DataHelper.StringNullorEmpty(o)) continue;
                    const value = Number(o);
                    if(isNaN(value)) continue;
                    const target = standardCols.find(x=>x.headId == value);
                    if(!target) continue;
                    target.visible = true;
                    this.columns.push(target);
                    useNum.push(value)
                }

                for(const o of hideList)
                {
                    if(DataHelper.StringNullorEmpty(o)) continue;
                    const value = Number(o);
                    if(isNaN(value)) continue;
                    const target = standardCols.find(x=>x.headId == value);
                    if(!target) continue;
                    target.visible = false;
                    this.columns.push(target);
                    useNum.push(value)
                }

                for(const o of allNum)
                {
                    if(useNum.find(x=>x === o))
                    {
                        continue;
                    }
                    const target = standardCols.find(x=>x.headId === o);
                    if(!target) continue;
                    this.columns.push(target);
                }
                this.columns = [...this.columns]
                this.dataGrid.refresh();
            }
        },
        async onClickColChooser()
        {
            await this.setCols();

            this.$nextTick(()=>{
                if(this.type == 'info')
                {
                    const originBtn = document.querySelector('#dataGridInfo .dx-datagrid-column-chooser-button')
                    originBtn.click();
                }
                else
                {
                    const originBtn = document.querySelector('#dataGrid .dx-datagrid-column-chooser-button')
                    originBtn.click();  
                }
            })
        },
        setFilteredData() //진행 완료 필터
        {
            this.dataSource = [];
            const temp = [];
            
            for(const data of this.tempDataSource)
            {
                if(this.procType == '완료' && this.isCompleted(data?.GYEYAK_GEUMAEK_AM,data?.GONGDONG))
                {
                    temp.push(data);
                }
                else if(this.procType == '보류' && DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT))
                {
                    temp.push(data);
                }
                else if(this.procType == '진행' && !(DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT)|| (data?.GYEYAK_GEUMAEK_AM === data?.GONGDONG || this.calcJanGum() == 0)))
                {
                    temp.push(data);
                }
                else if(this.procType == '전체')
                {
                    temp.push(data);
                }
            }
            const temp2 = [];
            for(const data of temp)
            {
                if(this.bonbu == '전체')
                {
                    temp2.push(data)
                }
                else if(this.bonbu == data.BONBU_NM)
                {
                    temp2.push(data)
                }
            }

            const temp3 = [];
            for(const data of temp2)
            {
                if(data.PJCODE_ID.includes(this.selProject) || data.PJ_NM.toString().toLowerCase().includes(this.selProject.toString().toLowerCase()) || data.BALJU_NM.toString().toLowerCase().includes(this.selProject.toString().toLowerCase()))
                {
                    temp3.push(data)
                }
            }
            
            this.dataSource = [...temp3];
            this.dataGrid?.refresh();
        },
        onRowPrePared(e)
        {
            const data = e.data;
            if(data?.GYEYAKIL_DT === undefined || data?.GYEYAK_GEUMAEK_AM === undefined) return;
            const index = e.component.getRowIndexByKey(e.key);
            this.$nextTick(()=>{
                const elements = e.component.getRowElement(index);
                if(!elements) return; 
                if(elements.length < 1) return;
                const row = elements[0];
                if(!row) return;


                if(this.isCompleted(data?.GYEYAK_GEUMAEK_AM,data?.GONGDONG)) //완료
                {
                    row.style.backgroundColor = '#EEF5F9'
                }
                else if(DataHelper.StringNullorEmpty(data?.GYEYAKIL_DT)) //보류
                {
                    row.style.color = '#aeaeae'
                }
            })
        },
        isCompleted(GYEYAK,GONGDONG){
            if(GYEYAK === undefined || GONGDONG === undefined) return;
            let v1 = Number(GYEYAK?.toString().replaceAll(',','').replaceAll(' ','')) ?? 0
            let v2 = Number(GONGDONG?.toString().replaceAll(',','').replaceAll(' ','')) ?? 0
            // let j = this.calcJanGum() ?? 0
            // console.log("inner j",j)
            

            const isValid = (v)=>{
                if(v === null || v === undefined || isNaN(v))
                {
                    return 0;        
                }
                return Number(v);
            }

            v1 = isValid(v1)
            v2 = isValid(v2)
            

            return (v1 <= v2);
        },
        async getYears()
        {
            const res = await RestHelper.RestSend('USP_SUGEUM','INIT',[{}]);
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite('수금 연도 요청',[res],this.logUse);
                const data = res._data[0];
                for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
                {   
                    this.yearList.push(i);
                }
                this.$nextTick(()=>{
                    const now = new Date();
                    this.year = now.getFullYear();
                })
            }
        },
        async getList(sangtae)
        {
            if(this.year == null)
                return;
            const res = await RestHelper.RestSend('USP_SUGEUM','LIST',[{
                SUGEUM_YEAR_NM : this.year,
                SANGTAE_NM : sangtae,
            }]);
            
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite('수금 리스트 요청',[res],this.logUse);
                return [...res._data];
            }
            return [];
        },
        async setDatasource()
        {
            this.dataSource = [];
            this.tempDataSource = [];
            this.s0List = [];
            this.s1List = [];
            this.s0List = await this.getList('0');
            this.s1List = await this.getList('1');
            if(this.s0List.length != this.s1List.length)return;
            for(let index = 0; index< this.s0List.length; index++)
            {
                const s0 = this.s0List[index];
                const s1 = this.s1List[index];
                const model = new SugeumModel(this.year);
                model.SANGTAE_NM = 0;
                model.SUJU_SQ = s0.SUJU_SQ;
                model.BONBU_NM = s0.BONBU_NM;
                model.PJCODE_ID = s0.PJCODE_ID;
                model.BUNRYU_CD = s0.BUNRYU_CD;
                model.EOPMU_CD = s0.EOPMU_CD;
                model.PJ_NM = s0.PJ_NM;
                model.BALJU_NM = s0.BALJU_NM;
                model.GYEYAK_GEUMAEK_AM = s0.GYEYAK_GEUMAEK_AM;
                model.GYEYAKIL_DT = s0.GYEYAKIL_DT;
                model.GONGDONG = s1.GONGDONG??0;
                model.PROJECT_SQ = s1.PROJECT_SQ;
                model.YONGYEOK_MAGAM_DT = s0.YONGYEOK_MAGAM_DT;
                model.YONGYEOK_SIJAK_DT = s0.YONGYEOK_SIJAK_DT;
                for(let i = 1; i<13; i++){model[`BIGO_MONTH_CN${i}`]=s0[`BIGO_MONTH_CN${i}`];}
                model.setSUGEUM_MONTH_CN_OBJ(0,s0);
                model.setSUGEUM_MONTH_CN_OBJ(1,s1);
                model.setMONTH_Data();
                this.dataSource.push(model)
            }
            this.tempDataSource = [...this.dataSource];
            await this.setInfos();
            this.setFilteredData();
            this.dataGrid.refresh();
        },
        async setInfos()
        {
            const res = await RestHelper.RestSend("USP_BUSEO","LIST",[{
                STARTYEAR : '0',
                ENDYEAR : DataHelper.getDate(),
                USE_YN : 'Y'
            }]);
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite('수금 부서 요청',[res],this.logUse);
                this.bonbuList = res._data.filter(x=>x.DEPT_LEVEL == 4);
            }
        },
        monthCellClass(data)
        {
            const col = data.column;
            const field = col.dataField;
            const rowData = data?.data;
            const s0 =rowData.SUGEUM_MONTH_CN_OBJ[`${field}_S0`];
            const s1 =rowData.SUGEUM_MONTH_CN_OBJ[`${field}_S1`];
            if(field.includes('TODAY_SUGEUM_MONTH_CN'))
            {
                return 'read';
            }
            if(s1)
            {
                if(s1 > 0)
                {
                    if(s1 != s0) return 'target2';
                    return 'target';
                }
            }
            return '';
        },
        monthCellValue(data,field)
        {
            const rowData = data?.data;
            const s1 = rowData.SUGEUM_MONTH_CN_OBJ[`${field}_S1`];
            
            if(isNaN(s1) || s1 == 0 || s1 == null)
            {
                rowData.SUGEUM_MONTH_CN_OBJ[`${field}_S0`] = rowData[field];
            }
            if(field.includes('TODAY_SUGEUM_MONTH_CN'))
            {
                rowData.TODAY_SUGEUM_MONTH_CN_S0 = rowData.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${this.nowMonth}_CN_S0`] ?? 0;
                rowData.TODAY_SUGEUM_MONTH_CN_S1 = rowData.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${this.nowMonth}_CN_S1`] ?? 0;
            }
            let strValue = rowData[field]?.toString().replace(/,/g,'');
            if(DataHelper.StringNullorEmpty(strValue))
            {
                return 0;
            }
            let ret = Number(strValue);
            if(this.type === 'info')
            {
                ret = ret/1000000;
                ret = Math.floor(ret);
            }
            return sf("{0:###,###,###,###,###,###,###,##0}", ret);
        },
        getMonthBigo(data)
        {
            const col = data.column;
            const field = col.dataField;
            const rowData = data?.data;
            if(field.includes('SUGEUM_MONTH'))
            {
                const field_split = field.split('_');
                if(field_split.length > 2)
                {
                    const index = field_split[1].replace('MONTH','');
                    const value = rowData[`BIGO_MONTH_CN${index}`];
                    if(!DataHelper.StringNullorEmpty(value))
                    {
                        return true;
                    }
                }
            }
            return false;
        },
        onEditStart(e)
        {
            const field = e.column.dataField;
            const rowData = e.data;
            this.editTarget = {
                field : field,
                rowData : rowData
            }
            if(rowData.SUGEUM_MONTH_CN_OBJ[`${field}_S1`])
            {
                if(rowData.SUGEUM_MONTH_CN_OBJ[`${field}_S1`] > 0)
                {
                    this.$nextTick(()=>{
                        e.component.closeEditCell()
                    })
                }
            }
        },
        onCellClick(e)
        {
            if(!e.data)return;
            if(this.type == 'info')return;
            const field = e.column.dataField;
            const rowData = e.data;
            this.editTarget = {
                field : field,
                rowData : rowData
            }
            ClipboardHelper.keyExpr = 'SUJU_SQ';
            ClipboardHelper.onCellClick(e);
        },
        async onClickEdit(e,target = null)
        {
            if(target!= null)
            {
                const col = target.column;
                const field = col.dataField;
                const rowData = target?.data;
                this.editTarget = {
                    'field' : field,
                    'rowData' : rowData,
                };
            }
            if(this.editTarget.field == undefined || this.editTarget.rowData == undefined)
            {
                this.toastMsg = "편집할 셀을 선택해주세요"
                this.toastType = 'error'
                this.toastVisible = true;
                return;
            }
            if(this.editTarget.field == null || this.editTarget.rowData == null)
            {
                this.toastMsg = "편집할 셀을 선택해주세요"
                this.toastType = 'error'
                this.toastVisible = true;
                return;
            }
            const res = await RestHelper.RestSend('USP_SUGEUM','INFO',[{
                SUGEUM_YEAR_NM : this.year,
                SANGTAE_NM : '',
                SUJU_SQ : this.editTarget.rowData.SUJU_SQ,
                SUGEUM_MONTH_NM : this.editTarget.field.split('_')[1].replace('MONTH',''),
            }])
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite('수금 편집 연도 요청',[res],this.logUse);
                this.formData = res._data[0];
                this.formData.SUGEUM_WON_AM1 = this.editTarget.rowData.SUGEUM_MONTH_CN_OBJ[this.editTarget.field+'_S0'];
                this.formData.SUGEUM_DATE_DT1 = DataHelper.SetDtData(this.formData.SUGEUM_DATE_DT1);
                this.formData.SUGEUM_DATE_DT2 = DataHelper.SetDtData(this.formData.SUGEUM_DATE_DT2);
                this.formData.SUJU_SQ =this.editTarget.rowData.SUJU_SQ;
                this.formData.SUGEUM_MONTH_NM =this.editTarget.field.split('_')[1].replace('MONTH','');
                this.formData.YONGYEOK_MAGAM_DT = this.editTarget.rowData.YONGYEOK_MAGAM_DT;
                this.formData.YONGYEOK_SIJAK_DT = this.editTarget.rowData.YONGYEOK_SIJAK_DT;
                
                if(this.formData.SUGEUM_WON_AM1 > 0)
                {
                    const temp = `${this.year}-${this.formData.SUGEUM_MONTH_NM.padStart(2, '0')}-${new Date(this.year, Number(this.formData.SUGEUM_MONTH_NM), 0).getDate()}`;
                    this.formData.SUGEUM_DATE_DT1 = DataHelper.SetDtData(temp)
                }
                
                this.popupVisible = true;
            }
        },
        getIsBetween(data)
        {
            const YONGYEOK_SIJAK_DT = moment(data.YONGYEOK_SIJAK_DT);
            const ysdt = moment(YONGYEOK_SIJAK_DT.date(1).format('YYYYMMDD'));
            const YONGYEOK_MAGAM_DT = moment(data.YONGYEOK_MAGAM_DT);
            const yedt = moment(YONGYEOK_MAGAM_DT.endOf('month').format('YYYYMMDD'));
            const targetMonth = ("0" + (data.SUGEUM_MONTH_NM)).slice(-2);
            const today = moment(`${this.year}${targetMonth}`);
            const isBetween = today.isBetween(ysdt, yedt, undefined, '[]'); 
            return isBetween;
        },
        async onClickPopupSave()
        {
            const data = {...this.formData};
            data.SUGEUM_DATE_DT1 = data.SUGEUM_DATE_DT1?.replace(/-/g,'');
            data.SUGEUM_DATE_DT2 = data.SUGEUM_DATE_DT2?.replace(/-/g,'');
            
            const isBetween = this.getIsBetween(data);
            
            if(data.SUGEUM_WON_AM1 > 0 && DataHelper.StringNullorEmpty(data?.SUGEUM_DATE_DT1.toString()))
            {
                this.toastMsg = "예상 수금일을 입력해주세요."
                this.toastType = 'error'
                this.toastVisible = true;
                return;   
            }
            if(DataHelper.StringNullorEmpty(data?.SUGEUM_DATE_DT2.toString()))
            {
                this.toastMsg = " 실제 수금일을 입력해주세요.."
                this.toastType = 'error'
                this.toastVisible = true;
                return;   
            }

            if(!isBetween)
            {
                if (confirm(`해당 월은 프로젝트의 용역기간과 맞지 않습니다.\r\n저장하시겠습니까?`) == false){    //확인
                    return;
                }
            }
            const project = {...this.dataSource.find(x=>x.SUJU_SQ == data.SUJU_SQ)};
            project.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${data.SUJU_MONTH_CN}_CN_S0`] = data.SUGEUM_WON_AM1;
            project.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${data.SUJU_MONTH_CN}_CN_S1`] = data.SUGEUM_WON_AM2;
            const total =this.calcTotal(project)
            const jang =this.calcJanGum(project)

            const selectedDate = this.getSelectedCellDate(); // 선택된 셀 yymm 가져오기

            if (selectedDate) {
                const selectedYear = selectedDate.year; // 선택된 연도
                const selectedMonth = selectedDate.month; // 선택된 월

                // formData에서 입력된 날짜 가져오기
                const ysSugeumdt = moment(this.formData.SUGEUM_DATE_DT1); // 예상수금일
                const Sugeumdt = moment(this.formData.SUGEUM_DATE_DT2); // 실제수금일

                // yymm까지만
                const isysSugeumdt = ysSugeumdt.year() === this.selectedYear && ysSugeumdt.month() + 1 === this.selectedMonth;
                const isSugeumdt = Sugeumdt.year() === this.selectedYear && Sugeumdt.month() + 1 === this.selectedMonth

                if (!isysSugeumdt || !isSugeumdt) {
                    const userConfirmed = confirm(`수금일은 해당 월의 날짜를 입력해주세요`);
                    if (!userConfirmed) {
                        return; 
                    }
                    return; 
                }
            }

            // const ysSugeumdt = this.formData.SUGEUM_DATE_DT1;
            // const Sugeumdt = this.formData.SUGEUM_DATE_DT2;

            // const SelectedCell = this.getSelectedCellDate;
            // console.log("선택한 셀::::", SelectedCell)
            
            // const formatYsSugeumdt = new Date(ysSugeumdt).toLocaleDateString();
            // const formatSugeumdt = new Date(Sugeumdt).toLocaleDateString();
            // const formatSelectedCell = new Date(SelectedCell).toLocaleDateString();


            // console.log("ysSugeumdt:", formatYsSugeumdt);
            // console.log("Sugeumdt:", formatSugeumdt);
            // console.log("SelectedCell:", formatSelectedCell);

            // // 예상수금일 또는 실제수금일이 선택된 셀과 다를 경우 confirm 창 표시
            // if (formatYsSugeumdt !== formatSelectedCell || formatSugeumdt !== formatSelectedCell) {
            //     const userConfirmed = confirm(`수금일은 해당 월의 날짜를 입력해주세요`);

            //     if (userConfirmed) {
            //         return;
            //     }
            // }

            if(total > jang)
            {
                if (confirm(`당해연도 합계가 잔금을 초과 합니다.\r\n저장하시겠습니까?`) == false){    //확인
                    return;
                }
            }

            const res = await RestHelper.RestSend('USP_SUGEUM','UPDATE',[{
                SUGEUM_YEAR_NM : this.year,
                SANGTAE_NM : '',
                SUJU_SQ : data.SUJU_SQ,
                SUGEUM_MONTH_NM	: data.SUGEUM_MONTH_NM,
                SUGEUM_MONTH_CN1: '',
                SUGEUM_MONTH_CN2: '',
                SUGEUM_MONTH_CN3: '',
                SUGEUM_MONTH_CN4: '',
                SUGEUM_MONTH_CN5: '',
                SUGEUM_MONTH_CN6: '',
                SUGEUM_MONTH_CN7: '',
                SUGEUM_MONTH_CN8: '',
                SUGEUM_MONTH_CN9: '',
                SUGEUM_MONTH_CN10: '',
                SUGEUM_MONTH_CN11: '',
                SUGEUM_MONTH_CN12: '',
                SUGEUM_WON_AM1: data.SUGEUM_WON_AM1,
                SUGEUM_WON_AM2: data.SUGEUM_WON_AM2,
                SUGEUM_DATE_DT1	: data.SUGEUM_DATE_DT1,
                SUGEUM_DATE_DT2	: data.SUGEUM_DATE_DT2,
                BIGO: data.BIGO,
            }])
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite('수금 확정 저장 요청',[res],this.logUse);
                await this.setDatasource();
                this.popupVisible = false;
            }
        },
        getYearAndMonthFromData(data) 
        {
            const YONGYEOK_SIJAK_DT = moment(data.YONGYEOK_SIJAK_DT); // 시작 날짜
            const YONGYEOK_MAGAM_DT = moment(data.YONGYEOK_MAGAM_DT); // 마감 날짜
            
            // 연도 및 월 추출
            const startYear = YONGYEOK_SIJAK_DT.year();
            const startMonth = YONGYEOK_SIJAK_DT.month() + 1; // month()는 0부터 시작하므로 +1
            const endYear = YONGYEOK_MAGAM_DT.year();
            const endMonth = YONGYEOK_MAGAM_DT.month() + 1; // month()는 0부터 시작하므로 +1

            return { startYear, startMonth, endYear, endMonth };
        },
        getSelectedCellDate() {
            const selectedCell = document.querySelector('.cell-selected'); // 선택된 셀 요소 가져오기

            if (selectedCell) {
                const cellContent = selectedCell.textContent.trim(); // 공백 제거
                console.log("선택한 셀 내용:", cellContent); // 선택한 셀 내용 확인
                
                // 연도와 월 추출을 위한 정규 표현식
                const match = cellContent.match(/(\d{4})[^0-9]*(\d{1,2})/); 

                if (match) {
                    const year = parseInt(match[1], 10); // 연도
                    const month = parseInt(match[2], 10); // 월
                    return { year, month }; // 연도와 월 반환
                }
            }

            console.log("유효한 셀을 찾을 수 없습니다.");
            return null; 
        },
        async onClickSave()
        {
            const params = [];
            for(const o of this.dataSource)
            {
                const data = {
                    SUGEUM_YEAR_NM : this.year,
                    SANGTAE_NM : '0',
                    SUJU_SQ : o.SUJU_SQ,
                    SUGEUM_MONTH_NM : '',
                    SUGEUM_MONTH1_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH1_CN_S0,
                    SUGEUM_MONTH2_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH2_CN_S0,
                    SUGEUM_MONTH3_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH3_CN_S0,
                    SUGEUM_MONTH4_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH4_CN_S0,
                    SUGEUM_MONTH5_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH5_CN_S0,
                    SUGEUM_MONTH6_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH6_CN_S0,
                    SUGEUM_MONTH7_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH7_CN_S0,
                    SUGEUM_MONTH8_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH8_CN_S0,
                    SUGEUM_MONTH9_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH9_CN_S0,
                    SUGEUM_MONTH10_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH10_CN_S0,
                    SUGEUM_MONTH11_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH11_CN_S0,
                    SUGEUM_MONTH12_CN : o.SUGEUM_MONTH_CN_OBJ.SUGEUM_MONTH12_CN_S0,
                    SUGEUM_WON_AM1 : '',
                    SUGEUM_WON_AM2 : '',
                    SUGEUM_DATE_DT1 : '',
                    SUGEUM_DATE_DT2 : '',
                    BIGO : '',
                };
            
                params.push(data)
            }
            const overCost = this.dataSource.filter(x=>this.calcTotal(x) > this.calcJanGum(x));
            if(overCost.length > 0)
            {
                const overArrTest = `${overCost.map(x=>this.rowText({value : x.SUJU_SQ}))}`;
                if(confirm(`당해연도 합계가 잔금을 초과 하는 프로젝트가 존재 합니다.\r\n저장 하시겠습니까?\r\n${overArrTest.replaceAll(',','\r\n')}`) == false)
                {
                    return;   
                }
            }
            
            let isBetween = false;
            const bList = [];
            for(const o of this.dataSource)
            {
                for(let m =1; m<=12; m++)
                {
                    if(o[`SUGEUM_MONTH${m}_CN`] == 0 || o[`SUGEUM_MONTH${m}_CN`] == null)continue;
                    o.SUGEUM_MONTH_NM = m;
                    isBetween = this.getIsBetween(o);
                    if(!isBetween)
                    {
                        bList.push(this.rowText({value : o.SUJU_SQ}));
                        isBetween = false;
                        continue;
                    }
                }
            }
            if(bList.length>0)
            {
                const overArrTest = `${bList}`;
                if (confirm(`프로젝트의 용역기간과 맞지 않는 프로젝트가 존재 합니다.\r\n저장하시겠습니까?\r\n${overArrTest.replaceAll(',','\r\n')}`) == false){    //확인
                    return;
                }
            }

            const res = await RestHelper.RestSend('USP_SUGEUM','INSERT',params);
            if(res._result[0].RESULTCD == '1')
            {
                LogHelper.logWrite('수금 저장 요청',[res],this.logUse);
                this.toastMsg = "저장 완료"
                this.toastType = 'success'
                this.toastVisible = true;
            }
            else
            {
                this.toastMsg = res._result[0].DETAILMSG
                this.toastType = 'error'
                this.toastVisible = true;
            }
        },
        foreachRange(selectedRange, func) {
            this.data = [];
            if (selectedRange.startRowIndex >= 0) {
                const minRowIndex = Math.min(
                    selectedRange.startRowIndex,
                    selectedRange.endRowIndex
                );
                const maxRowIndex = Math.max(
                    selectedRange.startRowIndex,
                    selectedRange.endRowIndex
                );
                const minColumnIndex = Math.min(
                    selectedRange.startColumnIndex,
                    selectedRange.endColumnIndex
                );
                const maxColumnIndex = Math.max(
                    selectedRange.startColumnIndex,
                    selectedRange.endColumnIndex
                );

                for (let rowIndex = minRowIndex; rowIndex <= maxRowIndex; rowIndex++) {
                    for ( let columnIndex = minColumnIndex; columnIndex <= maxColumnIndex; columnIndex++)
                    {
                        func(rowIndex, columnIndex);
                        this.data.push({ rowIndex: rowIndex, columnIndex: columnIndex });
                    }
                }
            }
        },
        showSelection() {
            const selectedCells = this.dataGrid.element().querySelectorAll('.cell-selected');

            if (selectedCells) {
                for (let i = 0; i < selectedCells.length; i++) {
                selectedCells[i].classList.remove('cell-selected');
                }
            }

            this.foreachRange(this.selectedRange, (rowIndex, columnIndex) => {
                const cell = this.dataGrid.getCellElement(rowIndex, columnIndex)
                cell.classList.add('cell-selected');
                cell.setAttribute('tabindex', 0);
                cell.removeEventListener('keydown',this.onCellKeyDown);
                cell.addEventListener('keydown',this.onCellKeyDown);
                cell.removeEventListener('keyup',this.onCellKeyUp);
                cell.addEventListener('keyup',this.onCellKeyUp);
                cell.focus();
            });
        },
        getCellValue(info) {
            return this.dataGrid.cellValue(info.rowIndex, info.columnIndex);
        },
        async onContentReady() {
        },
        onCellHoverChanged(e) {
            if(this.type == 'info')return;
            ClipboardHelper.keyExpr = 'SUJU_SQ';
            ClipboardHelper.onCellHoverChanged(e);
        },
        onCellPrepared(e) {
            if (e.rowType === 'data')
                this.cellInfos.push({
                    cellElement: e.cellElement,
                    rowIndex: e.rowIndex,
                    columnIndex: e.columnIndex,
                });
            e.cellElement.addEventListener('touchstart', () => {
                this.selectedRange.startRowIndex = e.rowIndex;
                this.selectedRange.endRowIndex = e.rowIndex;
                this.selectedRange.startColumnIndex = e.columnIndex;
                this.selectedRange.endColumnIndex = e.columnIndex;
                this.showSelection();
            });
        },
        getMapValue(key,map)
        {
            if(map.has(key))
            {
                return map.get(key)
            }
            return 0;
        },
        getMapSum(map)
        {
            let ret = 0;
            for(const o of map.values())
            {
                ret += Number(o);
            }
            return ret;
        },
        calcTotal(row)
        {
            let ret = 0;
            for(let i = 1; i<13; i++)
            {
                const s0 = Number(row.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${i}_CN_S0`] ?? 0) ?? 0;
                const s1 = Number(row.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${i}_CN_S1`] ?? 0) ?? 0;
                if(!isNaN(s1) && s1 > 0)
                {
                    ret += s1;
                    continue;
                }
                ret += s0;
            }
            return ret;
        },
        calcJanGum(row)
        {
            if(row?.GYEYAK_GEUMAEK_AM === undefined || row?.GONGDONG === undefined) return;

            const isValid = (v)=>{
                if(v === null || v === undefined || isNaN(v))
                {
                    return 0;        
                }
                return v;
            }

            const GYEYAK_GEUMAEK_AM = isValid(Number(row?.GYEYAK_GEUMAEK_AM));
            const GONGDONG = isValid(Number(row?.GONGDONG));

            return GYEYAK_GEUMAEK_AM - GONGDONG;
        },
        calcIwual(row)
        {
            return this.calcJanGum(row) - this.calcTotal(row);
        },
        openPms()
        {
            var _width = '1400';
            var _height = '220';

            var _left = Math.ceil((window.screen.width - _width )/2);
            var _top = Math.ceil((window.screen.height - _height )/2);

            if(this.editTarget.field == undefined || this.editTarget.rowData == undefined)
            {
                this.toastMsg = "프로젝트를 선택해주세요"
                this.toastType = 'error'
                this.toastVisible = true;
                return;
            }
            const pjId = this.editTarget.rowData.PJCODE_ID
            if(DataHelper.StringNullorEmpty(pjId))
            {
                this.toastMsg = " NO가 비어있습니다."
                this.toastType = 'error'
                this.toastVisible = true;
                return;
            }
            if(this.runningWindows.find(x=>pjId) != null) return;
            this.runningWindows.push(pjId);
            const newWindow = window.open(`./pms?year=${this.year}&pjId=${pjId}`,'',
            `width=${_width},height=${_height},top=${_top}, left=${_left}, location=no,toolbar=no,scrollbars=no,resizable=no,status=no,menubar=no`);
            const checkWindowClosed = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(checkWindowClosed);
                    this.runningWindows = this.runningWindows.filter(x=>x != pjId);
                    this.init();
                }
            }, 500); 
        },
        setDt(info)
        {
            let temp = info.valueText.toString().replaceAll('-','');
            return moment(temp).format("YYYY-MM-DD");
        },
        acountView(cellInfo) {
            let unit = this.type === 'info' ? 1000000 : 1;
            const value = Number(cellInfo.value);
            const ret = Math.floor(value/unit)
            const fomatString = sf("{0:###,###,###,###,###,###,###,##0}", ret)
            return `${fomatString}`;
        }
    }
}
</script>

<style>
#SUGEUM_view {
    overflow: auto;
}
#SUGEUM_view #dataGrid td {
    padding-top: 0rem!important;
    padding-bottom: 0rem!important;
    height: 1rem!important;
    border: 1px solid #e0e0e0 !important;
    font-size: 1.2rem!important;
    line-height: 3rem!important;
}
#SUGEUM_view #dataGridInfo td {
    padding-top: 0rem!important;
    padding-bottom: 0rem!important;
    height: 1rem!important;
    border: 1px solid #e0e0e0 !important;
    font-size: 1.2rem!important;
    line-height: 3rem!important;
}

#SUGEUM_view .dx-toolbar-items-container{
    height: 0.8rem;
}

#SUGEUM_view .dx-item-content .dx-button-content{

    display: none;
}

#SUGEUM_view .dx-item-content .dx-widget{
    display: none;
}
#SUGEUM_view td:has(div.read)
{
    background: #ececec6b;
}
/* 수정 start*/
#SUGEUM_view td:has(div.target)
{
    background: #FFF3C9;
}
#SUGEUM_view td:has(div.target2)
{
    background: rgb(238, 194, 194);
}
#SUGEUM_view .cell-selected{
    background: rgb(204, 217, 238) !important;
}

#SUGEUM_view > .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td:not(.dx-validation-pending):not(.dx-datagrid-select-all) {
    vertical-align: middle;
    text-align: left ;
}
/* 수정 start*/
#SUGEUM_view .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
    text-align: center !important;
}
#SUGEUM_view .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: 60px;
}

#SUGEUM_view .p-highlight {
    background:#fff !important;
    color:#787878;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}
#SUGEUM_view .p-selectbutton .p-button {
    background:#f8f8f8;
}

.hidden {
    display:none !important
}
.visible {
    display: flex !important;
}
#SUGEUM_view .dx-datagrid-total-footer > .dx-datagrid-content {
    padding: 0;
}
</style>