<template>
    <DxPopup ref="instance" title="프로젝트 검색" :visible="visible" :element-attr="{class:'overflow-hidden'}" width="80rem" height="70rem"  :show-close-button="true" @hidden="()=>{visible = false}">
        <div class="flex flex-row items-center justify-between w-full text-[1rem]">
            <p class="font-semibold w-[10rem] text-[1.4rem]">전체 프로젝트</p>
            <input type="text" class="border-2 h-[3rem] text-left rounded-lg w-[35rem] leading-[3rem] text-[1.2rem] pl-[0.7rem]" v-model="keyword"/>
            <div @click="onClickSearch" class="flex flex-row items-center rounded-lg border-2 h-[3rem] text-center ml-[1rem] w-[10rem] bg-[#787878] select-none cursor-pointer hover:bg-slate-50">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="white" class="w-[4rem] h-[1.6rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
                <p class="text-white text-[1.2rem] leading-[2rem]">검색</p>
            </div>
            <InputSwitch v-model="searchSwich" class="text-[1.4rem] ml-[2rem] mr-[1rem]" />
            <p class="text-[1.2rem] text-[#787878]">수금 완료 프로젝트 검색</p> 
        </div>
        <div class="m-[2rem]" ></div>
        <DataTable v-model:selection="selectedRow" :value="pjList" paginator :rows="12" size="none" tableStyle="width: 100%; height: 44rem;" selectionMode="single">
            <!-- <Column field="" header="" style="padding: 0.7rem; height: 1rem; width: 2.7rem; border: 1px solid #c7c7c7;"></Column> -->
            <Column field="SUGEUM_YEAR_CN" header="연도" style="padding: 0.7rem; height: 1rem; width: 9.5rem; border: 1px solid #c7c7c7; font-size:1.2rem"></Column>
            <Column field="PJCODE_ID" header="코드명" style="padding: 0.7rem; height: 1rem; width: 9.5rem; border: 1px solid #c7c7c7; font-size:1.2rem"></Column>
            <Column field="PJ_NM" header="PJ명" style="padding: 0.7rem; height: 1rem; width: 30rem; border: 1px solid #c7c7c7; font-size:1.2rem"></Column>
            <Column field="BALJU_NM" header="발주처" style="padding: 0.7rem; height: 1rem; width: 8rem; border: 1px solid #c7c7c7; font-size:1.2rem">
                <template #body="row">
                    <p class="h-[1.2rem] w-[8rem] overflow-hidden text-ellipsis py-0">{{ row.data.BALJU_NM }}</p>
                </template>
            </Column>
            <Column field="tpye" header="신규 / 변경" style="padding: 0.7rem; height: 1rem; width: 8rem; border: 1px solid #c7c7c7; font-size:1.2rem"></Column>
        </DataTable>
        <div class="flex flex-row justify-end">
            <div @click="select" class="flex flex-row items-center rounded-lg mr-[2rem] border-[#006EDD] border-2 w-[10rem] h-[4rem] font-semibold select-none cursor-pointer text-center justify-center mt-[2rem] hover:bg-slate-50">
                <p class="text-[#006EDD]">선택완료</p>
            </div>
            <div @click="()=>{visible = false;}" class="flex flex-row items-center rounded-lg border-[#006EDD] border-2 w-[10rem] h-[4rem] font-semibold select-none cursor-pointer text-center justify-center mt-[2rem] hover:bg-slate-50">
                <p class="text-[#006EDD]">닫기</p>
            </div>
        </div>
        <DxToast
        v-model:visible="toastVisible"
        v-model:message="toastMsg"
        v-model:type="toastType"
        :display-time="600"
        class="text-[1rem]"
        >
        <DxPosition my="top" at="top" offset="0 50"/>
        </DxToast>
    </DxPopup>
</template>

<script>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import { DxPopup } from 'devextreme-vue/popup';
import RestHelper from '../Helpers/RestHelper';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { DxToast, DxPosition } from 'devextreme-vue/toast';
import InputSwitch from 'primevue/inputswitch';

export default {
    components:{
        DxPopup,IconField,InputIcon,DxToast, DxPosition,
        DataTable,Column,ColumnGroup,InputSwitch
    },
    props:{
        callBack : Object,
        search : String,
    },
    data(){
        return{
            visible : false,
            keyword : '',
            pjAllList : [],
            pjList : [],
            selectedRow : {},
            toastVisible : false,
            toastMsg : "",
            toastType : "success",
            searchSwich:false,
        }},
    async mounted(){
        const res = await RestHelper.RestSend('USP_SUGEUM','ALL',[{
            'SUGEUM_YEAR_NM' : '',
            'SANGTAE_NM' : '1',
        }]);
        if(res._result[0].RESULTCD == 1)
        {
            this.pjAllList = [...res._data]
            for(const o of this.pjAllList)
            {
                o.tpye = o.GITA_CD == '신규계약' ? '신규계약' : `변경${o.BYEONGYEONG_CHASU_CN}차`
            }
            this.pjList = this.setList()
            // this.pjList.sort((a,b)=>{return a.PJCODE_ID - b.PJCODE_ID})
            this.setBlank();
        }
    },
    methods:{
        isCompleted(GYEYAK,GONGDONG){
            if(GYEYAK === undefined || GONGDONG === undefined) return;
            let v1 = Number(GYEYAK?.toString().replaceAll(',','').replaceAll(' ','')) ?? 0
            let v2 = Number(GONGDONG?.toString().replaceAll(',','').replaceAll(' ','')) ?? 0
            // let j = this.calcJanGum() ?? 0
            // console.log("inner j",j)
            

            const isValid = (v)=>{
                if(v === null || v === undefined || isNaN(v))
                {
                    return 0;        
                }
                return Number(v);
            }

            v1 = isValid(v1)
            v2 = isValid(v2)

            return (v1 <= v2);
        },
        async show(){
            this.visible = false;
            this.$nextTick(() => {
                this.visible = true;
            });           
            const res = await RestHelper.RestSend('USP_SUGEUM','ALL',[{
                'SUGEUM_YEAR_NM' : '',
                'SANGTAE_NM' : '1',
            }]);
            if(res._result[0].RESULTCD == 1)
            {
                this.keyword = this.search;
                this.pjAllList = [...res._data]
                for(const o of this.pjAllList)
                {
                    o.tpye = o.GITA_CD == '신규계약' ? '신규계약' : `변경${o.BYEONGYEONG_CHASU_CN}차`
                }
                this.pjList = this.setList()
                // this.pjList.sort((a,b)=>{return a.PJCODE_ID - b.PJCODE_ID})
                this.setBlank();
            }
        },
        async onClickSearch()
        {
            const res = await RestHelper.RestSend('USP_SUGEUM','ALL',[{
                'SUGEUM_YEAR_NM' : '',
                'SANGTAE_NM' : '1',
            }])
            if(res._result[0].RESULTCD == 1)
            {
                this.pjAllList = [...res._data]
                for(const o of this.pjAllList)
                {
                    o.tpye = o.GITA_CD == '신규계약' ? '신규계약' : `변경${o.BYEONGYEONG_CHASU_CN}차`
                }
                this.pjList = this.setList()
                // this.pjList.sort((a,b)=>{return a.PJCODE_ID - b.PJCODE_ID})
                this.setBlank();
            }
            this.toastMsg = '검색완료'
            this.toastType = 'success'
            this.toastVisible = true;
        },
        setList()
        {
            let ret = [...this.pjAllList.filter(x=>x.PJCODE_ID.toString().includes(this.keyword) || x.PJ_NM.includes(this.keyword) || x.BALJU_NM.includes(this.keyword))];
            if(this.searchSwich)
            {
                ret = ret.filter(x=>this.isCompleted(x?.GYEYAK_GEUMAEK_AM,x?.GONGDONG))
            }
            return ret;
        },
        select()
        {
            if(this.selectedRow == null || this.selectedRow == {} || Object.keys(this.selectedRow).length === 1)
            {
                this.toastMsg = '프로젝트를 선택해주세요'
                this.toastType = 'error'
                this.toastVisible = true;
                return;
            }
            this.visible = false;
            this.callBack(this.selectedRow)
        },
        setBlank()
        {
            if(this.pjList.length === 0)
            {
                for(let i = 0; i<12; i++)
                {
                    this.pjList.push({'order' : i})
                }
            }
            else if(this.pjList.length%12 < 12)
            {
                for(let i = 0; i<this.pjList.length%12; i++)
                {
                    this.pjList.push({'order' : i})
                }
            }
        },
    },
    computed:{
        instance(){
            return this.$refs.instance
        }
    }
}
</script>

<style scoped>
.p-selectable-row{
    height: 1rem;
}
.dx-toolbar-item-content {
    margin-right: 0 !important;
}
.dx-popup-title.dx-toolbar .dx-toolbar-after {
    display:flex;
    flex-direction: row;
    justify-items: flex-end;
    align-items: center;
}
</style>