<template>
    <div class="w-full h-full">
    <DxTabPanel id="tapPanel" class="w-full h-full"
        :animation-enabled="true"
        :repaint-changes-only="true"
        :data-source="items"
        :selectedItem="selectedItem"
        :swipe-enabled="false"
        :styling-mode="'primary'"
        @selection-changed="onTabSelectionChanged($event)"
        >
        <template #title="{ data: index }">
            <div class="flex justify-between w-full items-center ">
                <p class="text-[1.2rem] font-bold text-[#383E40]">{{ index.tabName }}</p>
                <div class="w-[2rem] h-[1.8rem] rounded-full bg-[#eaeaea] flex flex-row items-center justify-center">
                    <svg @click="tapClose(index)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#111" class="w-[1.2rem] h-[1.2rem] ">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
        </template>
        <template #itemTemplate="{ data: index }">
            <div class="m-0 p-0 border-t-2">
                <SUJU_View :type="index.type" v-if="index.name=='수주'" ref="SujuView"/>
                <SUGEUM_View :type="index.type" v-if="index.name=='수금'" ref="SugeumView"/>
                <BUNBAE_View :type="index.type" v-if="index.name=='배분'"/>
                <MAECHUL_View :type="index.type" v-if="index.id=='B0050'"/>
                <MEACHUL_MANAGE_View :type="index.type" v-if="index.id=='C0060'"/>
                <ProjectManagementView :type="index.type" v-if="index.name=='단가'"/>
                <BIYONG_View :type="index.type" v-if="index.name=='비용'"/>
                <INWON_INPUT_View :type="index.type" v-if="index.id=='C0070' || index.id=='B0060'"/>
                <MOKPYO_View :type="index.type" v-if="index.name=='목표'"/>
                <Home2_View v-if="index.name=='실적현황'"/>
                <Dash3_View v-if="index.name=='성과총괄표'"/>
                <PersonView v-if="index.tabName=='시스템 > 인원'"/>
                <DepartmentView v-if="index.name=='부서'"/>
                <UR_View v-if="index.id=='A0020'"/>
                <DetailUR_View v-if="index.name=='세부UR'"/>
                <Account_View v-if="index.name=='권한'"/>
            </div>
        </template>
    </DxTabPanel>
</div>
</template>

<script>
import DxTabPanel from 'devextreme-vue/tab-panel';
import SUJU_View from './Views/SUJU_View'
import SUGEUM_View from './Views/SUGEUM_View'
import BUNBAE_View from './Views/BUNBAE_View'
import MAECHUL_View from './Views/MAECHUL_View'
import MEACHUL_MANAGE_View from './Views/MEACHUL_MANAGE_View'
import MOKPYO_View from './Views/MOKPYO_View'
import ProjectManagementView from './Views/ProjectManagementView'
import PersonView from './Views/PersonView'
import DepartmentView from './Views/DepartmentView'
import BIYONG_View from './Views/BIYONG_View'
import INWON_INPUT_View from './Views/INWON_INPUT_View'
import Home2_View from './Views/Home2_View'
import Dash3_View from './Views/Dash3_View'
import UR_View from './Views/UR_View'
import DetailUR_View from './Views/DetailUR_View'
import Account_View from './Views/Account_View'
export default {
    components: {
        DxTabPanel,
        SUJU_View,
        BUNBAE_View,
        ProjectManagementView,
        MEACHUL_MANAGE_View,
        DepartmentView,
        PersonView,
        BIYONG_View,
        MAECHUL_View,
        MOKPYO_View,
        SUGEUM_View,
        INWON_INPUT_View,
        Home2_View,
        Dash3_View,
        UR_View,DetailUR_View,
        Account_View
    },
    props:{
        onChangeNav : Object,
    },
    data(){
        return{
            items : [],//'매출 입력','수주 현황','매출 현황'
            selectedItem : {}
        }
    },
    methods :{
        async onTabSelectionChanged(e)
        {
            let closeElem = document.querySelectorAll('.dx-popup-normal .dx-widget .dx-icon-close')
            closeElem.forEach(x=>x.click())

            const itemName = e.addedItems[0]
            this.selectedItem = itemName;
            this.onChangeNav(itemName);

            const SujuView = this.$refs.SujuView;
            const SugeumView = this.$refs.SugeumView;
            await this.$nextTick();
            const colEditPop = document.querySelector('.dx-popup-wrapper > .dx-overlay-content');
            if (colEditPop) {
                colEditPop.style.setProperty('opacity', '0', 'important');
            }

            
            if (SujuView && SujuView.getVisibleColumns) {
                await SujuView.onOptionChanged(e);
            } 
            else if (SugeumView && SugeumView.getVisibleColumns) {
                await SugeumView.onOptionChanged(e);
            }

            if (this.dataGrid) {
                await this.dataGrid.refresh(); 
            }
        },
        tapClose(index)
        {
            if(this.items.length > 1)
            {
                this.items = this.items.filter(x=>x != index);
            }
        }
    }
}
</script>

<style>
.dx-pivotgrid-container .dx-expand-icon-container{
    display: none !important;
    width: 0 !important;
    height: 0 !important;
}
.dx-pivotgrid-container .dx-expand{
    display: none !important;
    width: 0 !important;
    height: 0 !important;
}
/* tab style 설정 */
.dx-tabpanel .dx-tab {
    width: calc((100vw - 178px) / 10.5);
    height: 35px;
    background: #f5f7f9;
    padding: 0 10px;
}
.dx-tabpanel .dx-tabs {
    border-bottom: 0 !important;
}
.dx-tab-selected {
    background:#fff !important;
    border-right: 1px solid #d9d9d9;
}
.dx-tab.dx-tab-selected::before {
    position: unset !important;
    background-color:0;
}

</style>