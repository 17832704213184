import RestHelper from "../Helpers/RestHelper";
import DataHelper from "../Helpers/DataHelper";
import moment from 'moment';

class Meachul_ViewModel{
    async USP_MAECHUL_INIT() {
        const res = await RestHelper.RestSend('USP_MAECHUL', 'INIT', [{}]);
        if (res._result[0].RESULTCD === '1') {
            const data = [...res._data];
    
            for (const project of data) {
                const detailRes = await RestHelper.RestSend('USP_PJ_INDANGDANGA', 'SEARCH', [{
                    PROJECT_SQ: project.PROJECT_SQ
                }]);
                if (detailRes._result[0].RESULTCD === '1' && detailRes._data.length > 0) {
                    const detail = detailRes._data[0];
                    project.BUSEOMYEONG_NM = detail.BUSEOMYEONG_NM || '주관부서';
                    project.GITA_CD = detail.GITA_CD || '기타';
                } else {
                    project.BUSEOMYEONG_NM = '';
                    project.GITA_CD = '';
                }
            }
            return data;
        }
    }
    
    async USP_MAECHUL_LIST(id)
    {
        const res = await RestHelper.RestSend('USP_MAECHUL','LIST',[{
            KEYWORD : '',
            PROJECT_SQ : id,
            INWON_SQ : '',
        }])
        if(res._result[0].RESULTCD === '1')
        {
            const data = [...res._data]
            for(const o of data)
            {
                for(let m = 1; m<=12; m++)
                {
                    o[`MAECHUL_MONTH${m}_CN`] = o[`MAECHUL_MONTH${m}_CN`].toString().split('/');
                }
                o.TUIP_Il_CN = '';
            }
            return data;  
        }
    }

    getTotalMonths(startDate, endDate) {
        // 시작일과 마감일을 Date 객체로 변환
        const start = new Date(startDate);
        const end = new Date(endDate);

        // 총 개월 수 계산
        const totalMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
        
        return totalMonths+1;
    }

    autoInput(data,proj)
    {
        if(DataHelper.StringNullorEmpty(data?.HYEONGTAE_NM))return;
        if(DataHelper.StringNullorEmpty(data?.TUIP_GAEWOL_CN))return;
        if(DataHelper.StringNullorEmpty(data?.HYEONGTAE_NM))return;
        
        let monthUR = 1;
        if(data?.HYEONGTAE_NM === '비상주')
        {
            if(DataHelper.StringNullorEmpty(proj?.BISANGJU_MAGAM_DT))return;
            if(DataHelper.StringNullorEmpty(proj?.BISANGJU_SIJAK_DT))return;
            const time1 = moment(proj?.BISANGJU_SIJAK_DT)
            const time2 = moment(proj?.BISANGJU_MAGAM_DT)
            const wp = this.getTotalMonths(time1.format('YYYY-MM-DD'),time2.format('YYYY-MM-DD'))
            monthUR = data.TUIP_GAEWOL_CN / (wp);
        }
        
        for(let m = 1; m<=12; m++)
        {
            const mDt = data[`MAECHUL_MONTH${m}_CN`];
            for(const i in mDt)
            {
                mDt[i] = 0;
            } 
        }

        const date = new Date(data.TUIP_Il_CN);
        // const period = Number(data.TUIP_GAEWOL_CN);
        const stDate = moment(date).format('YYYY-MM-DD');
        // const enDate = moment(date).add(period,'months').format('YYYY-MM-DD');
        const yearList = data.MAECHUL_YEAR_CN.split('/');

        const getMM = (dt)=>{
            const date = new Date(dt);
            const lastDate = new Date(date.getFullYear(),date.getMonth()+1,0);
            const a = lastDate.getDate();
            const b = date.getDate();

            const c = (a-b+1)/a;
            return c;
        }

        try{
            let dt = moment(stDate);
            let mm = data.TUIP_GAEWOL_CN;
            while(mm > monthUR)
            {
                const year = dt.year();
                const yearIndex = yearList.indexOf(year.toString());
                if(yearIndex === -1)break;
                
                let calMM = Math.round((getMM(dt) * monthUR)*1000)/1000;

                data[`MAECHUL_MONTH${dt.month()+1}_CN`][yearIndex] = calMM;
                mm -= calMM;
                if(dt.format('YYYY-MM') === moment(stDate).format('YYYY-MM'))
                {
                    dt = dt.add(1,'months').subtract(moment(stDate).date()-1,'days');
                }
                else
                {
                    dt = dt.add(1,'months');
                }
            }
            
            let addDt = moment(stDate).date()-1;
            addDt = addDt > dt.daysInMonth()-1 ? dt.daysInMonth()-1 : addDt;
            dt = dt.add(addDt,'days');
            const year = dt.year();
            const yearIndex = yearList.indexOf(year.toString());
            if(yearIndex === -1) return;
            if(mm < 0.0001) mm = 0;
            data[`MAECHUL_MONTH${dt.month()+1}_CN`][yearIndex] = mm;
        }
        catch(error)
        {
            console.log(error)
        }
        
    }

    async save(data)
    {
        const params = [];
        for(const o of data)
        {
            const yearList = o.MAECHUL_YEAR_CN.split('/');
            for(const i in yearList)
            {
                const index = Number(i);
                const year = yearList[index]

                const temp = {
                    KEYWORD : '',
                    PROJECT_SQ : o.PROJECT_SQ,
                    INWON_SQ : o.INWON_SQ,
                    MAECHUL_YEAR_CN : year,
                    TUIP_DT : '',
                    MAECHUL_MONTH1_CN : o.MAECHUL_MONTH1_CN[index].toString(),
                    MAECHUL_MONTH2_CN : o.MAECHUL_MONTH2_CN[index].toString(),
                    MAECHUL_MONTH3_CN : o.MAECHUL_MONTH3_CN[index].toString(),
                    MAECHUL_MONTH4_CN : o.MAECHUL_MONTH4_CN[index].toString(),
                    MAECHUL_MONTH5_CN : o.MAECHUL_MONTH5_CN[index].toString(),
                    MAECHUL_MONTH6_CN : o.MAECHUL_MONTH6_CN[index].toString(),
                    MAECHUL_MONTH7_CN : o.MAECHUL_MONTH7_CN[index].toString(),
                    MAECHUL_MONTH8_CN : o.MAECHUL_MONTH8_CN[index].toString(),
                    MAECHUL_MONTH9_CN : o.MAECHUL_MONTH9_CN[index].toString(),
                    MAECHUL_MONTH10_CN : o.MAECHUL_MONTH10_CN[index].toString(),
                    MAECHUL_MONTH11_CN : o.MAECHUL_MONTH11_CN[index].toString(),
                    MAECHUL_MONTH12_CN : o.MAECHUL_MONTH12_CN[index].toString(),
                    INDANGDANGA_SQ : o.INDANGDANGA_SQ,
                }
                params.push(temp)
            }
        }

        return await RestHelper.RestSend('USP_MAECHUL','UPDATE',params);
    }
}

export default new Meachul_ViewModel();