<template>
<div class="w-full h-full px-[1rem] py-[1rem] bg-white font-NotoSansKR" id="SUJU_View">
    <div v-if="type!='info'" class="flex flex-row justify-between items-center w-full mb-[1rem]">
        <p class="text-[2.2rem] leading-[2.6rem] font-semibold text-[#2b3133] tracking-tighter">{{ type == 'info' ? '수주현황' : '수주관리' }}</p>
    </div>
    <SelectButton v-if="type=='info'" v-model="tabType" :options="[{ value: '실적현황' },{ value: '프로젝트 리스트' }]" class="flex text-center mb-[2rem] justify-around items-center w-[27.6rem] h-[4rem] bg-[#f8f8f8] rounded-lg p-[4px]" optionLabel="value" dataKey="value" aria-labelledby="custom">
        <template #option="slotProps">
            <p class="cursor-pointer select-none text-[1.1rem] rounded-lg w-[13.2rem] text-center py-[0.9rem] h-[3.2rem]]">{{ slotProps.option.value }}</p>
        </template>
    </SelectButton>
    <div v-if="type=='info'" class="flex flex-row items-center justify-between mb-[1rem] pl-[0rem] pb-0 w-full">
        <div class="h-full flex flex-row items-center">
            <p class="mr-[1rem] text-[#92959B] text-[1.3rem]">조회연도</p>
            <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
                <option v-for="y in yearList" :key="y">{{ y }}</option>
            </select>
        </div>
        
        <div class="flex flex-row items-center justify-end">
            <div v-if="tabType.value == '프로젝트 리스트'" @click="onClickColChooser" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-white border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer">컬럼 조정</div> 
            <div v-if="tabType.value == '프로젝트 리스트'" @click="onClickColExcelDown" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-white border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer">엑셀 다운로드</div> 
            <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </div>
        </div>
    </div>
    <div class="w-full border-b-2 mb-[1.6rem] "></div>
    <BarLineChart :year="year" v-if="type=='info' && tabType.value != '프로젝트 리스트'"/>
    <div v-if="type!='info'" class="flex flex-row items-center mt-[0rem] pl-[0rem] pb-0 w-full ">
        <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
            <option v-for="y in yearList" :key="y">{{ y }}</option>
        </select>
        <div class="text-[#92959B] mr-[1.5rem] text-[1.3rem]"></div>
        <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="month">
            <option v-for="m in monthList" :key="m">{{ m }}</option>
        </select>
        <div class="text-[#92959B] mr-[1.5rem] text-[1.3rem]"></div>
        <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="selBuseo">
            <option v-for="bonbu in buseoList" :key="bonbu">{{ bonbu.BUSEOMYEONG_NM }}</option>
        </select>
        <div class="text-[#92959B] mr-[1.5rem] text-[1.3rem]"></div>
        <div class="flex flex-row items-center">
            <input @keydown="searchKeyDown" type="text" v-model="keyword" placeholder="PJ코드, PJ명, 발주처 검색" class="border-[1px] px-[1rem] rounded-lg h-[3.7rem] w-[50rem]">
            <div @click="searchCallback" class="flex flex-row items-center justify-center ml-[2rem] rounded-lg w-[11rem] h-[3.7rem] bg-[#787878] select-none cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#fff" class="w-[1.6rem] h-[2rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
                <p class="text-white">검색</p>
            </div>
        </div>
    </div>
    <div v-if="type!='info'" class="flex flex-row justify-between items-end py-[1rem] ">
        <div></div>
        <div class="flex justify-end">
            <div @click="onClickColExcelDown" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-gray-100 border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer">엑셀 다운로드</div> 
            <!-- <div v-if="type!='info'" @click="onClickTemp" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-gray-100 border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer">엑셀 불러오기</div>  -->
            <div v-if="type!='info'" @click="onClickAdd" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-white border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer">+ 수주 등록</div> 
            <div @click="onClickColChooser" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-white border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer" >컬럼 조정</div> 
            <div @click="historySidebar.open()" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-white border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer">변경 조회</div> 
            <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </div>
        </div>
    </div>
    <div :class="hiddenGrid()" class="flex flex-row w-full h-[calc(100%-15rem)]">
        <DxDataGrid v-if="type=='info'" v-show="type!='info' || tabType.value == '프로젝트 리스트'" id="OmGridInfo" ref="dataGrid1" @option-changed="onOptionChanged" width="100%" :height="`100%`" column-resizing-mode="widget"
            :allow-column-resizing="true" :allow-column-reordering="true" :columns="columns" :column-auto-width="true" @row-prepared="onRowPrePared($event)"
            :data-source="data" key-expr="SUJU_SQ" :show-borders="true" @row-dbl-click="onRowClick" @exporting="onExporting" @selection-changed="onSelectionChanged($event)" @scroll="onScrollGrid" class=" w-full h-calc(100%-18rem)">
            <DxPaging :enabled="false"/>
            <DxSelection mode="single" />
            <DxEditing startEditAction="dblClick" mode="cell" :allow-updating="false" :allow-adding="this.type!='info'" :allow-deleting="false"></DxEditing>
            <DxHeaderFilter :visible="true" />
            <DxScrolling mode="'virtual" useNative="true" ScrollbarMode="always"/> 
            <DxLoadPanel :enabled="true"/>
            <DxExport :enabled="true"/>
            <DxColumnChooser title="컬럼 편집" empty-panel-text="숨길 컬럼을 이 영역으로 드래그" :enabled="true">
                <DxPosition my="right bottom" at="right bottom" of="dataGrid"></DxPosition>
            </DxColumnChooser> 
            <DxColumnFixing :enabled="true"/>
            <DxMasterDetail :enabled="true" template="masterDetailTemplate"/>
            <template #masterDetailTemplate="{data : col}">
                <div class="w-full flex flex-row items-center justify-start h-10 bg-[#f5f5f5] border-[1px]">
                    <div class="h-10 flex flex-row items-center justify-center my-0 w-full">
                        <div class="flex items-center justify-center whitespace-nowrap">
                            <div class="inline-block w-15rem">
                                <div @click="onClickEdit(col)" class="select-none w-28 h-full leading-[2.4rem] text-[1rem] font-semibold text-[#272727] text-center hover:text-[#686868] cursor-pointer">{{ type == 'info' ? '상세 보기' : '수주 편집' }}</div> 
                            </div>
                            <div v-if="type != 'info'" class="inline-block w-15rem">
                                <div @click="onClickDelete(col)" class="w-28 select-none h-full leading-[2.4rem] text-[1rem] font-semibold text-[#272727] text-center hover:text-[#686868] cursor-pointer">수주 삭제</div> 
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #first-cell-template="{ data: info }">
                <div class="flex flex-row items-center">
                    <div :style="{background : `#${info.data.BUSEO_COLOR_NM}`}" class="rounded-lg w-[5px] h-[16px] mr-[1rem]"></div>
                    <div>{{ info.data.BUSEOMYEONG_NM }}</div>
                </div>
            </template>
        </DxDataGrid>
        <DxDataGrid v-if="type!='info'" v-show="type!='info' || tabType.value == '프로젝트 리스트'" id="OmGrid" ref="dataGrid2" @option-changed="onOptionChanged" width="100%" :height="`100%`" column-resizing-mode="widget"
            :allow-column-resizing="true" :allow-column-reordering="true" :columns="columns" :column-auto-width="true" @row-prepared="onRowPrePared($event)"
            :data-source="data" key-expr="SUJU_SQ" :show-borders="true" @row-dbl-click="onRowClick" @exporting="onExporting" @selection-changed="onSelectionChanged($event)" @scroll="onScrollGrid" class=" w-full h-calc(100%-18rem)">
            <DxPaging :enabled="false"/>
            <DxSelection mode="single" />
            <DxEditing startEditAction="dblClick" mode="cell" :allow-updating="false" :allow-adding="this.type!='info'" :allow-deleting="false"></DxEditing>
            <DxHeaderFilter :visible="true" />
            <DxScrolling mode="'virtual" useNative="true" ScrollbarMode="always"/> 
            <DxLoadPanel :enabled="true"/>
            <DxExport :enabled="true"/>
            <DxColumnChooser title="컬럼 편집" empty-panel-text="숨길 컬럼을 이 영역으로 드래그" :enabled="true">
                <DxPosition my="right bottom" at="right bottom" of="dataGrid"></DxPosition>
            </DxColumnChooser> 
            <DxColumnFixing :enabled="true"/>
            <DxMasterDetail :enabled="true" template="masterDetailTemplate"/>
            <template #masterDetailTemplate="{data : col}">
                <div class="w-full flex flex-row items-center justify-start h-10 bg-[#f5f5f5] border-[1px]">
                    <div class="h-10 flex flex-row items-center justify-center my-0 w-full">
                        <div class="flex items-center justify-center whitespace-nowrap">
                            <div class="inline-block w-15rem">
                                <div @click="onClickEdit(col)" class="select-none w-28 h-full leading-[2.4rem] text-[1rem] font-semibold text-[#272727] text-center hover:text-[#686868] cursor-pointer">{{ type == 'info' ? '상세 보기' : '수주 편집' }}</div> 
                            </div>
                            <div v-if="type != 'info'" class="inline-block w-15rem">
                                <div @click="onClickDelete(col)" class="w-28 select-none h-full leading-[2.4rem] text-[1rem] font-semibold text-[#272727] text-center hover:text-[#686868] cursor-pointer">수주 삭제</div> 
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #first-cell-template="{ data: info }">
                <div class="flex flex-row items-center">
                    <div :style="{background : `#${info.data.BUSEO_COLOR_NM}`}" class="rounded-lg w-[5px] h-[16px] mr-[1rem]"></div>
                    <div>{{ info.data.BUSEOMYEONG_NM }}</div>
                </div>
            </template>
        </DxDataGrid>
    
        <Dialog class="w-[70rem] h-[70rem] p-[1rem] bg-white" v-model:visible="editPopupVisible" :header="editType" modal>
            <template #header>
                <div class="w-full h-full">
                    <span class="text-[1.6rem] font-bold white-space-nowrap">{{ editType }}</span>
                </div>
            </template>
            <div class="mt-[1rem]"></div>
            <SelectButton v-if="editType == '수주등록'" v-model="viewType" :options="[{ value: '신규계약' },{ value: '변경계약' }]" class="text-center w-full" optionLabel="value" dataKey="value" aria-labelledby="custom">
                <template #option="slotProps">
                    <p class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg w-[34rem] text-center py-[0.4rem]">{{ slotProps.option.value }}</p>
                </template>
            </SelectButton>
            <SelectButton v-if="editType != '수주등록'" v-model="viewType" :options="[{ value: '신규계약' },{ value: '변경계약' }]" class="text-center w-full" optionLabel="value" dataKey="value" aria-labelledby="custom" disabled>
                <template #option="slotProps">
                    <p class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-[1px] rounded-lg w-[34rem] text-center py-[0.4rem]">{{ slotProps.option.value }}</p>
                </template>
            </SelectButton>
            <div class="mt-[1rem]"></div>
            <div class="grid grid-cols-12 h-[52rem] w-full text-[1.2rem]">
                <div v-if="viewType.value == '신규계약'" class="col-span-6 flex flex-col gap-2 p-[1rem]">
                    <label for="PJCODE_ID">PJ코드</label>
                    <InputText v-if="type === 'info'" disabled id="PJCODE_ID" v-model="formData.PJCODE_ID" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                    <InputText v-if="type != 'info'"  id="PJCODE_ID" v-model="formData.PJCODE_ID" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                </div>
                <div v-if="viewType.value == '신규계약'" class="col-span-6 flex flex-col gap-2 p-[1rem]">
                    <label for="PJ_NM">PJ명*</label>
                    <InputText v-if="type === 'info'" disabled id="PJ_NM" v-model="formData.PJ_NM" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                    <InputText v-if="type != 'info'"  id="PJ_NM" v-model="formData.PJ_NM" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                </div>
                <div v-if="viewType.value != '신규계약'" class="col-span-6 flex flex-col gap-2 p-[1rem]">
                    <label for="PJCODE_ID">PJ코드</label>
                    <InputText v-if="editType != '수주등록' && type != 'info'" id="PJCODE_ID" v-model="formData.PJCODE_ID" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                    <InputText v-if="editType != '수주등록' && type == 'info'" disabled id="PJCODE_ID" v-model="formData.PJCODE_ID" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                    <p v-if="editType === '수주등록'" @click="projectSearch.show" class="h-[2rem] border-[1px] px-[1rem] text-[#495057]">{{formData.PJCODE_ID}}</p>
                    <!-- <InputText id="PJCODE_ID" v-model="formData.PJCODE_ID" class="border-[1px] px-[1rem]" aria-describedby="username-help" disabled/> -->
                </div>
                <div v-if="viewType.value != '신규계약'" class="col-span-6 flex flex-col gap-2 p-[1rem]">
                    <label for="PJ_NM">PJ명*</label>
                    <InputText v-if="type === 'info'" disabled id="username" v-model="formData.PJ_NM" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                    <InputText v-if="type != 'info'"  id="username" v-model="formData.PJ_NM" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                    <!-- <p v-if="editType != '수주등록'" class="h-[2rem] border-[1px] px-[1rem] text-[#495057]">{{formData.PJ_NM}}</p>
                    <p v-if="editType === '수주등록'" @click="projectSearch.show" class="h-[2rem] border-[1px] px-[1rem] text-[#495057]">{{formData.PJ_NM}}</p> -->
                    <!-- <InputText id="PJ_NM" v-model="formData.PJ_NM" allowEmpty="false" class="border-[1px] px-[1rem]" aria-describedby="username-help" disabled/> -->
                </div>
                <div class="col-span-6 row-start-2 flex flex-col gap-2 p-[1rem]">
                    <label for="username">PJ담당자</label>
                    <InputText v-if="type === 'info'" disabled id="username" v-model="formData.DAMDANG_NM" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                    <InputText v-if="type != 'info'"  id="username" v-model="formData.DAMDANG_NM" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                </div>
                <div class="col-span-6 row-start-2 flex flex-col gap-2 p-[1rem]">
                    <label for="username">발주처*</label>
                    <InputText v-if="type === 'info'" disabled id="username" v-model="formData.BALJU_NM" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                    <InputText v-if="type != 'info'"  id="username" v-model="formData.BALJU_NM" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                </div>
                <div class="col-span-6 row-start-3 flex flex-col gap-2 p-[1rem]">
                    <label for="username">계약일*</label>
                    <input v-if="type === 'info'" id="dt1" v-model="formData.GYEYAKIL_DT"  readonly  class="border-[1px] w-full outline-none" type="date"/>
                    <input v-if="type != 'info'"  id="dt1" v-model="formData.GYEYAKIL_DT" @change="getBuseo" class="border-[1px] w-full outline-none" type="date"/>
                </div>
                <div class="col-span-6 row-start-3 flex flex-col gap-2 p-[1rem]">
                    <label for="username">수주 예상월*</label>
                    <input v-if="type === 'info'" disabled id="dt2" v-model="formData.SUJUIL_DT" readonly class="border-[1px] w-full outline-none" type="date"/>
                    <input v-if="type != 'info'"  id="dt2" v-model="formData.SUJUIL_DT" class="border-[1px] w-full outline-none" type="date"/>
                </div>
                <div class="col-span-3 row-start-4 flex flex-col gap-2 p-[1rem]">
                    <label>수주부서*</label>
                    <Dropdown v-if="type === 'info'" disabled v-model="formData.BUSEOMYEONG_NM" :options="this.buseoList.filter(x=>x.BUSEO_SQ).map(x=>x.BUSEOMYEONG_NM)" class="w-full p-[0.2rem] md:w-[14rem] border-[1px]" />
                    <Dropdown v-if="type != 'info'"  v-model="formData.BUSEOMYEONG_NM" :options="this.buseoList.filter(x=>x.BUSEO_SQ).map(x=>x.BUSEOMYEONG_NM)" class="w-full p-[0.2rem] md:w-[14rem] border-[1px]" />
                </div>
                <div class="col-span-3 row-start-4 flex flex-col gap-2 p-[1rem]">
                    <label for="username">수주분류*</label>
                    <Dropdown v-if="type === 'info'" disabled v-model="formData.BUNRYU_CD" :options="this.options.BUNRYU.map(x=>x.NAME)" class="w-full p-[0.2rem] md:w-[14rem] border-[1px]" />
                    <Dropdown v-if="type != 'info'"  v-model="formData.BUNRYU_CD" :options="this.options.BUNRYU.map(x=>x.NAME)" class="w-full p-[0.2rem] md:w-[14rem] border-[1px]" />
                </div>
                <div class="col-span-3 row-start-4 flex flex-col gap-2 p-[1rem]">
                    <label for="username">업무분류*</label>
                    <Dropdown v-if="type === 'info'" disabled v-model="formData.EOPMU_CD" :options="this.options.EOPMU.map(x=>x.NAME)" class="w-full p-[0.2rem] md:w-[14rem] border-[1px]" />
                    <Dropdown v-if="type != 'info'"  v-model="formData.EOPMU_CD" :options="this.options.EOPMU.map(x=>x.NAME)" class="w-full p-[0.2rem] md:w-[14rem] border-[1px]" />
                </div>
                <div class="col-span-3 row-start-4 flex flex-col gap-2 p-[1rem]">
                    <label for="username">수주확률*</label>
                    <Dropdown v-if="type === 'info'" disabled v-model="formData.SUJU_HAKYUL_CD" :options="this.options.SUJU_YUL.map(x=>x.NAME)" class="w-full p-[0.2rem] md:w-[14rem] border-[1px]" />
                    <Dropdown v-if="type != 'info'"  v-model="formData.SUJU_HAKYUL_CD" :options="this.options.SUJU_YUL.map(x=>x.NAME)" class="w-full p-[0.2rem] md:w-[14rem] border-[1px]" />
                </div>
                <div class="col-span-3 row-start-5 flex flex-col gap-2 p-[1rem]">
                    <label v-if="viewType.value == '신규계약'" for="username">계약금액(부가세 별도)*</label>
                    <label v-if="viewType.value != '신규계약'" for="username">계약 증가금액*</label>
                    <InputNumber v-if="type === 'info'" disabled id="username" v-model="formData.GYEYAK_WON_AM" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" />
                    <InputNumber v-if="type != 'info'"  id="username" v-model="formData.GYEYAK_WON_AM" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" />
                </div>
                <div class="col-span-3 row-start-5 flex flex-col gap-2 p-[1rem]">
                    <label for="username">배분율(PCM 지분율 기재)*</label>
                    <InputNumber v-if="type === 'info'" disabled id="username" v-model="formData.BEABUN_YUL_RT" suffix="%" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0"/>
                    <InputNumber v-if="type != 'info'"  id="username" v-model="formData.BEABUN_YUL_RT" suffix="%" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0"/>
                </div>
                <div class="col-span-3 row-start-5 flex flex-col gap-2 p-[1rem]">
                    <label for="username">수주지분(PCM)*</label>
                    <InputNumber  id="username" :modelValue="calculate_PCM_JIBUN_AM(formData)" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" disabled/>
                </div>
                <div class="col-span-3 row-start-5 flex flex-col gap-2 p-[1rem]">
                    <label for="username">수주지분(설계)*</label>
                    <InputNumber  id="username" :modelValue="calculate_SEOLGYE_JIBUN_AM(formData)" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" disabled/>
                </div>
                <div class="col-span-3 row-start-6 flex flex-col gap-2 p-[1rem]">
                    <label for="username">배분 전*</label>
                    <InputNumber  id="username" :modelValue="formData.GYEYAK_WON_AM" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" disabled/>
                </div>
                <div class="col-span-3 row-start-6 flex flex-col gap-2 p-[1rem]">
                    <label for="username">배분 후*</label>
                    <InputNumber  id="username" :modelValue="calculate_PCM_JIBUN_AM(formData)" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" disabled/>
                </div>
                <div class="col-span-3 row-start-6 flex flex-col gap-2 p-[1rem]">
                    <label for="username">배분 전(확률반영)*</label>
                    <InputNumber  id="username" :modelValue="calculate_BEABUN_JEON_YUL_AM(formData)" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" disabled/>
                </div>
                <div class="col-span-3 row-start-6 flex flex-col gap-2 p-[1rem]">
                    <label for="username">배분 후(확률반영)*</label>
                    <InputNumber  id="username" :modelValue="calculate_BEABUN_HU_YUL_AM(formData)" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" disabled/>
                </div>
                <div v-if="viewType.value != '신규계약'" class="col-span-3 row-start-7 flex flex-col gap-2 p-[1rem]">
                    <label for="username">변경 전 계약금액</label>
                    <InputNumber  id="username" :modelValue="calculate_Before()" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" disabled/>
                </div>
                <div v-if="viewType.value != '신규계약'" class="col-span-3 row-start-7 flex flex-col gap-2 p-[1rem]">
                    <label for="username">변경 후 계약금액</label>
                    <InputNumber  id="username" :modelValue="calculate_After()" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" disabled/>
                </div>
                <div v-if="viewType.value != '신규계약'" class="col-span-3 row-start-7 flex flex-col gap-2 p-[1rem]">
                    <label for="username">변경계약 Version</label>
                    <InputNumber  id="username" :modelValue="formData.BYEONGYEONG_CHASU_CN" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" disabled/>
                </div>
                <div v-if="viewType.value != '신규계약'" class="col-span-3 row-start-7 flex flex-col gap-2 p-[1rem]">
                    <label for="username">변경계약 전 Version</label>
                    <InputNumber  id="username" :modelValue="formData.LAST_VERSION_CN" class="border-[1px] px-[1rem] py-[0.4rem]" aria-describedby="username-help"  :minFractionDigits="0" :maxFractionDigits="5" disabled/>
                </div>
                <div class="col-span-12 row-start-8 flex flex-col gap-2 p-[1rem]">
                    <label for="username">메모</label>
                    <InputText v-if="type === 'info'" disabled id="username" v-model="formData.MEMO_NM" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                    <InputText v-if="type != 'info'"  id="username" v-model="formData.MEMO_NM" class="border-[1px] px-[1rem]" aria-describedby="username-help" />
                </div>
            </div>
            <div class="flex flex-row items-end justify-end gap-[1rem] border-[0px] w-full h-[8rem]">
                <Button label="닫기" @click="onClickClose" class="w-[8rem] h-[3rem] bg-[#fff] text-[#03A9F4] border-[#03A9F4] border-[1px] hover:bg-[#e9e8e8]"/>
                <Button v-if="type != 'info'" label="저장" @click="onClickSave" class="w-[8rem] h-[3rem] bg-[#03A9F4] text-white border-[1px] hover:bg-[#0595d8]"/>
            </div>
        </Dialog>
    </div>
    <ProjectSearchPopup ref="projectSearch" :callBack="pjSearchCallback"/>
    <HistorySidebar ref="historySidebar" menuName="C0010"/>
    </div>
</template>

<script>
import {
DxDataGrid,
DxPaging,
DxEditing,
DxHeaderFilter,
DxScrolling,
DxSelection,
DxMasterDetail,
DxColumnChooser,
DxLoadPanel,
DxColumnFixing,
DxExport
} from 'devextreme-vue/data-grid';
import { DxNumberBox } from "devextreme-vue/number-box";
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxForm, { DxItem, DxGroupItem, DxLabel } from 'devextreme-vue/form';
import OmModel from '../Models/OmModel.js'
import RestHelper from '../Helpers/RestHelper'
import DataHelper from '../Helpers/DataHelper'
import LogHelper from '../Helpers/LogHelper';
import SelectButton from 'primevue/selectbutton';
import DxTextBox from 'devextreme-vue/text-box';
import ProjectSearchPopup from '../Controls/ProjectSearchPopup.vue'
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import BarLineChart from './Charts/BarLineChart.vue'
import HistorySidebar from '../Controls/HistorySidebar.vue';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';

export default {
    components :{
        DxDataGrid,
        DxNumberBox,
        DxPaging,
        DxEditing,
        DxPopup,
        DxHeaderFilter,
        DxForm,
        DxScrolling,
        DxSelection,
        DxLoadPanel,DxExport,
        DxToolbarItem,HistorySidebar,
        DxGroupItem,BarLineChart,
        DxLabel,Dropdown,Button,
        DxMasterDetail,InputNumber,
        DxItem,Dialog,InputText,
        DxColumnChooser,ProjectSearchPopup,
        DxTextBox,
        DxColumnFixing,SelectButton
    },
    props:{
        type : String,
    },
    computed:{
        editPopup()
        {
            return this.$refs.editPopup?.instance;
        },
        dataGrid()
        {
            if(this.type == 'info')
            {
                return this.$refs.dataGrid1?.instance;
            }
            return this.$refs.dataGrid2?.instance;
        },
        textSize()
        {
            return ['1rem','1.2rem','1.4rem']
        },
        // cellWidth()
        // {
        //     return 'auto'
        // },
        monthList()
        {
            const ret = ['전체']
            for(let i = 1; i<13; i++)ret.push(i);
            return ret
        },
        projectSearch()
        {
            return this.$refs.projectSearch;
        },
        historySidebar()
        {
            return this.$refs.historySidebar;
        }
    },
    data(){
        return{
            yearList : [],
            year : null,
            month : '전체',
            keyword : '',
            selBuseo : {},
            data : [],
            tempData : [],
            columns : [],
            buseoList : [],
            bonbuList : [],
            preSumMap : new Map(), //예상 합계
            targetSumMap : new Map(), // 목표 합계
            textType : 0,
            selectedData : null,
            formData : new OmModel(),
            originData : {},
            validationGroupName: "gridForm",
            editPopupVisible : false,
            editType : '수주등록',
            saveButtonOptions : {
                stylingMode: 'contained',
                text: '저장',
                onClick : this.onClickSave
            },
            closeButtonOptions : {
                stylingMode: 'outlined',
                text: '닫기',
                onClick : this.onClickClose
            },
            viewType : { value: '신규계약' },
            tabType : { value: '실적현황' },
            options : { BUNRYU : [], BUSEO : [], EOPMU : [], GITA : [], SUJU_YUL : []},
            colManage:{}, 
            colInfo:{},
            currentTab:'프로젝트 리스트'
        }
    },
    watch:{
        textType()
        {
            const cells = document.getElementById('OmGrid').getElementsByTagName('td');
            for(const o of cells)
            {
                o.style.fontSize = this.textSize[this.textType];
            }
        },
        year()
        {
            this.searchCallback();
        },
        month()
        {
            this.searchCallback();
        },
        selBuseo()
        {
            this.searchCallback();
        },
        viewType()
        {
            this.$nextTick(()=>{
                this.formData = {};
                this.formData.GITA_CD = this.viewType.value;
            })
        },
        tabType(n,o)
        {
            if(n == null)
            {
                this.tabType = o;
            }
        }
    },
    async mounted(){
        await this.getYears();
        await this.initData();
        this.options = await DataHelper.InitSujuCodeData();
        
        this.$nextTick(()=>{
            this.setCols();
            this.searchCallback();
        })
    },
    methods:{
        changeTab(tab)
        {
            this.currentTab = tab;
        },
        async init()
        {
            this.year = '';
            await this.getYears();
            await this.initData();
            this.options = await DataHelper.InitSujuCodeData();
            
            this.$nextTick(()=>{
                this.setCols();
                this.searchCallback();
            })
        },
        searchKeyDown(e)
        {
            if(e.code == 'Enter' || e.code == 'NumpadEnter')
            {
                this.searchCallback();
            }
        },
        searchCallback(){
            this.data = [];
            let temp = this.tempData.filter(x=>x.SUJU_WAL.split('-')[0] == this.year);
            if(this.month != '전체')
            {
                temp = temp.filter(x=>x.SUJU_WAL.split('-')[1].toString().includes(this.month));
            }
            if(this.selBuseo != '전체')
            {
                temp = temp.filter(x=>x.BUSEOMYEONG_NM == this.selBuseo);
            }
            if(!DataHelper.StringNullorEmpty(this.keyword))
            {
                temp = temp.filter(x=>x.PJCODE_ID.includes(this.keyword) || x.PJ_NM.includes(this.keyword) || x.BALJU_NM.includes(this.keyword))
            }
            this.data = [...temp];
            this.dataGrid?.refresh();
        },
        async getYears()
        {
            const res = await RestHelper.RestSend('USP_SUJU','YEAR',[{}]);
            LogHelper.logWrite(`수주 연도 요청`,[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const now = new Date();
                const data = res._data[0];
                for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
                {   
                    this.yearList.push(i);
                }
                if(this.yearList.length < 1)
                {
                    this.yearList = [now.getFullYear()];
                }
                this.$nextTick(()=>{
                    this.year = now.getFullYear();
                })
            }
        },
        async setCols()
        {
            const pageName = this.type === 'info' ? '수주현황' : '수주관리';
            const standardCols = [
                {
                    headId : 1,
                    cellTemplate : "first-cell-template",
                    caption : '수주부서',
                    width : this.cellWidth,
                    dataField : 'BUSEOMYEONG_NM'
                },
                {
                    headId : 2,
                    caption : 'PJ코드',
                    dataField : 'PJCODE_ID',
                    width : this.cellWidth,
                },
                {
                    headId : 3,
                    caption : '담당자',
                    dataField : 'DAMDANG_NM',
                    width : this.cellWidth
                },
                {
                    headId : 4,
                    caption : '수주분류',
                    dataField : 'BUNRYU_CD',
                    width : this.cellWidth
                },
                {
                    headId : 5,
                    caption : '업무분류',
                    dataField : 'EOPMU_CD',
                    width : this.cellWidth
                },
                {
                    headId : 6,
                    caption : '기타분류',
                    dataField : 'GITA_CD',
                    width : this.cellWidth
                },
                {
                    headId : 7,
                    caption : 'PJ명',
                    dataField : 'PJ_NM',
                    width : '40rem'
                },
                {
                    headId : 8,
                    caption : '발주처',
                    dataField : 'BALJU_NM',
                    width : this.cellWidth
                },
                {
                    headId : 9,
                    caption : '계약일',
                    dataField : 'GYEYAKIL_DT',
                    width : this.cellWidth
                },
                {
                    headId : 10,
                    caption : '수주월',
                    dataField : 'SUJU_WAL',
                    width : this.cellWidth
                },
                {
                    headId : 11,
                    caption : '수주확률',
                    dataField : 'SUJU_HAKYUL_CD',
                    width : this.cellWidth
                },
                {
                    headId : 12,
                    caption : '수주 지분(PCM)',
                    calculateCellValue : this.calculate_PCM_JIBUN_AM,
                    format:"###,##0",
                    width : this.cellWidth
                },
                {
                    headId : 13,
                    caption : '수주 지분(설계)',
                    calculateCellValue : this.calculate_SEOLGYE_JIBUN_AM,
                    format:"###,##0",
                    width : this.cellWidth
                },
                {
                    headId : 14,
                    caption : '배분율',
                    dataField : 'BEABUN_YUL_RT',
                    format:"#0.00'%'",
                    width : this.cellWidth
                },
                {
                    headId : 15,
                    caption : '계약금액',
                    dataField : 'GYEYAK_WON_AM',
                    format:"###,##0",
                    width : this.cellWidth
                },
                {
                    headId : 16,
                    caption : '배분 전',
                    dataField : 'GYEYAK_WON_AM',
                    format:"###,##0",
                    width : this.cellWidth
                },
                {
                    headId : 17,
                    caption : '배분 전(확률반영)',
                    calculateCellValue : this.calculate_BEABUN_JEON_YUL_AM,
                    format:"###,##0",
                    width : this.cellWidth
                },
                {
                    headId : 18,
                    caption : '배분 후',
                    calculateCellValue : this.calculate_PCM_JIBUN_AM,
                    format:"###,##0",
                    width : this.cellWidth
                },
                {
                    headId : 19,
                    caption : '배분 후(확률반영)',
                    calculateCellValue : this.calculate_BEABUN_HU_YUL_AM,
                    format:"###,##0",
                    width : this.cellWidth
                },
            ];
            const res = await RestHelper.RestSend('USP_COLUMN','LIST',[{
                USER_ID : 1,
                PAGE_NM : pageName
            }])
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data[0];
                const sortList = [...new Set((data.COLUMN_INDEX_NM || '').split(','))]; // null 체크 추가
                const hideList = [...new Set((data.COLUMN_HIDE_NM || '').split(','))]; // null 체크 추가
                
                const useNum = [];
                const allNum = standardCols.map(x=>x.headId);
                this.columns = [];
                for(const o of sortList)
                {
                    if(DataHelper.StringNullorEmpty(o)) continue;
                    const value = Number(o);
                    if(isNaN(value)) continue;
                    const target = standardCols.find(x=>x.headId == value);
                    if(!target) continue;
                    target.visible = true;
                    this.columns.push(target);
                    useNum.push(value)
                }

                for(const o of hideList)
                {
                    if(DataHelper.StringNullorEmpty(o)) continue;
                    const value = Number(o);
                    if(isNaN(value)) continue;
                    const target = standardCols.find(x=>x.headId == value);
                    if(!target) continue;
                    target.visible = false;
                    this.columns.push(target);
                    useNum.push(value)
                }

                for(const o of allNum)
                {
                    if(useNum.find(x=>x === o))
                    {
                        continue;
                    }
                    const target = standardCols.find(x=>x.headId === o);
                    if(!target) continue;
                    this.columns.push(target);
                }
                this.columns = [...this.columns]
                this.dataGrid.refresh();
            }
        },
        onOptionChanged(e)
        {
            if(e.name != "columns")return;
            const pageName = this.type === 'info' ? '수주현황' : '수주관리';

            const visibleCols = e.component.getVisibleColumns().filter(x=>x.headId != undefined).map(x=>x.headId);
            this.$nextTick(async ()=>{
                const hideCols = this.columns.filter(x=>visibleCols.find(y=>y == x.headId) == null).map(x=>x.headId);
                
                let vString = '';
                let hString = '';

                for(const o of visibleCols)
                {
                    vString += o + ','
                }
                for(const o of hideCols)
                {
                    hString += o + ','
                }
                vString =  vString.slice(0,-1);
                hString =  hString.slice(0,-1);

                if(DataHelper.StringNullorEmpty(vString))
                {
                    return;
                }
                const res = await RestHelper.RestSend('USP_COLUMN','UPDATE',[{
                    USER_ID : 1,
                    PAGE_NM : pageName,
                    COLUMN_INDEX_NM : vString,
                    COLUMN_HIDE_NM : hString,
                }])
                if(res._result[0].RESULTCD == '1')
                {
                    LogHelper.logWrite('컬럼 변경 저장',[res],this.logUse);
                }
            })
        },
        onRowPrePared(e)
        {
            const data = e.data;
            const index = e.component.getRowIndexByKey(e.key);
            this.$nextTick(()=>{
                const elements = e.component.getRowElement(index);
                if(!elements) return; 
                if(elements.length < 1) return;
                const row = elements[0];
                if(!row) return;
                if(data?.SUJU_HAKYUL_CD === '완료')
                {
                    row.style.backgroundColor = '#EEF5F9'
                }
                else if(data?.SUJU_HAKYUL_CD === 'F')
                {
                    row.style.color = '#aeaeae'
                }
            })
        },
        onRowClick(e)
        {
            e.component.collapseAll(-1)
            if(e.isExpanded)
            {
                e.component.collapseRow(e.key);
            }
            else
            {
                e.component.expandRow(e.key);
            }
        },
        onSelectionChanged(e){
            const data = e?.selectedRowsData[0];
            this.selectedData = data;
        },
        onClickAdd()
        {
            this.editType = '수주등록';
            this.editPopupVisible = true;
            this.viewType.value = '신규계약';
            this.formData = new OmModel();
            this.formData.GITA_CD = this.viewType.value;
        },
        async onClickDelete(col)
        {
            const data = col.data;
            const res =  await RestHelper.RestSend("USP_SUJU","DELETE",[{SUJU_SQ : data.SUJU_SQ,}]);
            if(res._result[0].RESULTCD == '1')
            {   alert("해당 수주를 삭제합니다.");
                const tempMonth = this.month;
                const tempBuseo = this.selBuseo;
                await this.initData();
                this.dataGridRefresh();
                this.month = "";
                this.selBuseo = "";
                this.$nextTick(()=>{
                    this.month = tempMonth;
                    this.selBuseo = tempBuseo;
                });
            }
            else if(res._result[0].RESULTCD == '9999')
            {
                alert(res._result[0].DETAILMSG);
            }
        },
        onClickEdit(col)
        {
            this.editType = '수주편집';
            this.viewType.value = col.data.GITA_CD
            this.editPopupVisible = true;
            this.formData = {...col.data};
            this.originData = {...col.data};
        },
        async onClickSave()
        {
            this.formData.GITA_CD = this.viewType.value;
            this.formData.LAST_VERSION_CN = this.formData?.LAST_VERSION_CN ?? 0;
            for(const key of Object.keys(this.formData))
            {
                let isNeed = true;
                const value = this.formData[key]
                switch(key)
                {
                    case "SUJU_SQ":
                    case "BUSEO_CD":
                    case "BUSEO_SQ":
                    case "PJCODE_ID":
                    case "DAMDANG_NM":
                    case "PCM_JIBUN_AM":
                    case "SEOLGYE_JIBUN_AM":
                    case "BEABUN_JEON_WON_AM":
                    case "BEABUN_JEON_YUL_AM":
                    case "BEABUN_HU_WON_AM":
                    case "BEABUN_HU_YUL_AM":
                    case "MEMO_NM":
                    case "BYEONGYEONG_CHASU_CN":
                    case "BYEONGYEONG_WON_AM":
                    case "ORDER_NO":
                        isNeed = false;
                        break;
                }
                if(value?.toString() == '' && isNeed)
                {
                    alert(`${key},${value}빈값입니다.`)
                    return;
                }
            }
            this.formData.BUSEO_SQ = this.buseoList.find(x=>x.BUSEOMYEONG_NM == this.formData.BUSEOMYEONG_NM)?.BUSEO_SQ;
            this.formData.BEABUN_HU_YUL_AM = this.calculate_BEABUN_HU_YUL_AM(this.formData);
            await this.save();
            this.editPopupVisible = false;
            await this.initData();
            this.dataGridRefresh();
            this.searchCallback();
        },
        async save()
        {
            let command = this.editType === '수주등록' ? 'INSERT' : 'UPDATE';
            if(this.formData.GITA_CD != '변경계약')
            {
                command += 'NEW'
            }
            const res = await RestHelper.RestSend('USP_SUJU',command,[this.formData]);
            LogHelper.logWrite(`수주 저장`,[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                alert('저장되었습니다.');
            }
            else
            {
                alert('저장에 실패하였습니다.');
            }
        },
        onClickClose()
        {
            for(const key of Object.keys(this.originData))
            {
                this.formData[key] = this.originData[key];
            }
            this.editPopupVisible = false;
        },
        onClickColChooser()
        {
            if(this.type == 'info')
            {
                const originBtn = document.querySelector('#OmGridInfo .dx-datagrid-column-chooser-button')
                originBtn.click();
            }
            else
            {
                const originBtn = document.querySelector('#OmGrid .dx-datagrid-column-chooser-button')
                originBtn.click();
            }
        },
        applyColumnSettings(settings)
        {
            console.log("적용할 컬럼 설정:", settings);
        },
        onExporting(e)
        {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('수주');

            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), '수주.xlsx');
                });
            });

            e.cancel = true;
        },
        onClickColExcelDown()
        {
            if(this.type == 'info')
            {
                const originBtn = document.querySelector('#OmGridInfo .dx-datagrid-export-button')
                originBtn.click();
            }
            else
            {
                const originBtn = document.querySelector('#OmGrid .dx-datagrid-export-button')
                originBtn.click();
            }
        },
        onClickTemp()
        {
            alert('준비중 입니다.');
        },
        onScrollGrid()
        {
            this.dataGrid.component.collapseAll(-1)
        },
        dataGridRefresh()
        {
            // this.dataGrid.refresh();
            // if(this.$viewMap.has("OrderInfomationView"))
            // {
            //     this.$viewMap.get("OrderInfomationView").data = [...this.data]
            //     this.$viewMap.get("OrderInfomationView").dataGrid.refresh();
            // }
        },
        async getBuseo()
        {
            let dt = this.formData.GYEYAKIL_DT;
            if(DataHelper.StringNullorEmpty(dt))
            {
                dt = new Date();
                dt = dt.toLocaleDateString()
                dt = dt.replaceAll(" ","0");
                dt = dt.replaceAll(".","");
            }
            else
            {
                dt = dt.replaceAll("-","");
            }
            const res = await RestHelper.RestSend("USP_BUSEO","SUJULIST",[{
                STARTYEAR : dt,
                ENDYEAR : '',
                USE_YN : ''
            }]);
            const data = res._data;
            this.bonbuList = data.filter(x=>x.DEPT_LEVEL == 4);
            this.bonbuList =[{BUSEOMYEONG_NM : '전체'}]
            this.buseoList = this.bonbuList.concat(data.filter(x=>x.DEPT_LEVEL == 5));
        },
        async initData()
        {
            await this.getBuseo();
            this.tempData = await DataHelper.InitSujuData();
            if(this.type=='info')
            {
                this.tempData = this.tempData.filter(x=>(x.BUNRYU_CD != "사내협약")&&(x.BUNRYU_CD != "사후정산")&&(x.BUNRYU_CD != "OT"))
            }
            this.data = [...this.tempData];
            this.dataGrid.repaint();
            this.getSum();
            this.$nextTick(()=>{
                this.selBuseo = this.bonbuList[0].BUSEOMYEONG_NM;
            })
        },
        getToday(y = 0 ,m = 0 ,d = 0)
        {
            const date = new Date();
            const year = date.getFullYear() + y;
            const month = ("0" + (1 + date.getMonth() + m)).slice(-2);
            const day = ("0" + (date.getDate() + d)).slice(-2);

            return year + "-" + month + "-" + day;
        },
        async getSum()
        {
            if(this.data.length > 0)
            {
                const groups = this.data.reduce((acc,curr)=>{
                    const {BONBU_ID} = curr;
                    if(acc[BONBU_ID]){
                        acc[BONBU_ID].push(curr);
                    }
                    else acc[BONBU_ID] = [curr];
                    return acc;
                },{});
                const keys = [...new Set(this.data.map(x=>x.BONBU_ID))];
                for(const key of keys)
                {
                    let preValue = groups[key].reduce((acc,curr)=>{ acc += curr.BEABUN_JEON_WON_AM; return acc},0);
                    let targetValue = groups[key].filter(x=>x.SUJU_HAKYUL_CD =='완료').reduce((acc,curr)=>{ acc += curr.BEABUN_JEON_WON_AM; return acc},0);
                    preValue /= 100000000;
                    targetValue /= 100000000;
                    this.preSumMap.set(key,preValue);
                    this.targetSumMap.set(key,targetValue);
                }
            }
            
            return 60;
        },
        getMapValue(key,map)
        {
            if(map.has(key))
            {
                return map.get(key)
            }
            return 0;
        },
        getMapSum(map)
        {
            let ret = 0;
            for(const o of map.values())
            {
                ret += o;
            }
            return ret;
        },
        calculate_PCM_JIBUN_AM(rowData) // 수주지분(PCM)
        {
            try{
                const rate = rowData.BEABUN_YUL_RT / 100;
                return (rowData?.GYEYAK_WON_AM * rate)??0;
            }
            catch{
                return 0;
            }
        },
        calculate_SEOLGYE_JIBUN_AM(rowData) // 수주지분(설계)
        {
            try
            {
                const rate = rowData.BEABUN_YUL_RT / 100;
                return rowData.GYEYAK_WON_AM * (1 - rate);
            }
            catch
            {
                return 0;
            }
        },
        calculate_BEABUN_JEON_YUL_AM(rowData) // 배분 전(확률반영)
        {
            try
            {
                const value = rowData.GYEYAK_WON_AM;
                const type = rowData.SUJU_HAKYUL_CD;
                let rate = this.getSUJU_HAKYUL(type);
                
                return value * rate;
            }
            catch
            {
                return 0;
            }
        },
        calculate_BEABUN_HU_YUL_AM(rowData) // 배분 후(확률반영)
        {
            try
            {
                const value = this.calculate_PCM_JIBUN_AM(rowData);
                const type = rowData.SUJU_HAKYUL_CD;
                let rate = this.getSUJU_HAKYUL(type);
                
                return value * rate;
            }
            catch
            {
                return 0;
            }
        },
        getSUJU_HAKYUL(data)
        {
            if(DataHelper.StringNullorEmpty(data)) return 0;
            if(data == 'B') return 0.5;
            if(data == 'A' || data == '완료') return 1;
            return 0;
        },
        calculate_version(rowData,delta = 0)
        {
            try
            {
                let version = Number(rowData.LAST_VERSION_CN);
                if(isNaN(version))
                {
                    version = 0;
                }
                return version+delta;
            }
            catch
            {
                return 0;
            }
        },
        pjSearchCallback(data)
        {
            const temp = this.tempData.filter(x=>x.PARENTS_SQ == data.PARENTS_SQ).sort((a,b)=>{ return a.BYEONGYEONG_CHASU_CN > b.BYEONGYEONG_CHASU_CN ? -1 : 1})[0];
            this.formData = {...temp};
            this.formData.GYEYAK_WON_AM = 0;
            this.formData.BYEONGYEONG_CHASU_CN = this.formData?.BYEONGYEONG_CHASU_CN ?? 0;
            
            if(this.formData.BYEONGYEONG_CHASU_CN < 1)
            {
                this.formData.BYEONGYEONG_CHASU_CN = 1;
            }
            else
            {
                this.formData.BYEONGYEONG_CHASU_CN += 1;
            }
            this.formData.LAST_VERSION_CN = this.formData.BYEONGYEONG_CHASU_CN - 1;
            this.formData.LAST_SQ = this.formData.PARENTS_SQ;
        },
        calculate_Before()
        {
            try{
                const index = Number(this.formData.BYEONGYEONG_CHASU_CN);
                if(isNaN(index) && index > 0)
                {
                    return 0;
                }
                const beforeDatas = this.tempData.filter(x=>x.PARENTS_SQ == this.formData.PARENTS_SQ).filter(x=>x.BYEONGYEONG_CHASU_CN < index);
                if(beforeDatas.length > 0)
                {
                    let total = 0;
                    for(const value of beforeDatas)
                    {
                        total += Number(value.GYEYAK_WON_AM);
                    }
                    return Number(total);
                }
                return 0;
            }
            catch
            {
                return 0;
            }
        },
        calculate_After()
        {
            const target = this.calculate_Before();
            if( target > 0)
            {
                this.formData.BYEONGYEONG_WON_AM = target + Number(this.formData.GYEYAK_WON_AM);
                return target + Number(this.formData.GYEYAK_WON_AM);
            }
            return 0;
        },
        hiddenGrid()
        {
            if(this.type!='info' || this.tabType.value == '프로젝트 리스트')
            {
                this.$nextTick(()=>{
                    this.dataGrid?.refresh();
                })
                return 'visible';
            }
            else
            {
                return 'hidden';
            }
        },
    }
}
</script>

<style>

#OmGrid .dx-toolbar-items-container{
    height: 0.8rem;
}

#OmGrid .dx-item-content .dx-button-content{
    visibility: hidden;
    display: none;
}

#OmGrid .dx-item-content .dx-widget{
    visibility: hidden;
    display: none;
}
#OmGrid td{
    padding:0 8px;
    height:25px;
    border: 1px solid #e0e0e0!important;
    text-align: left !important;
}
#OmGrid .dx-header-row {
    height: 25px;
    text-align: center;
    width: 100%;
    font-size: 1.3rem;
}

#OmGridInfo .dx-toolbar-items-container{
    height: 0.8rem;
}

#OmGridInfo .dx-item-content .dx-button-content{
    visibility: hidden;
    display: none;
}

#OmGridInfo .dx-item-content .dx-widget{
    visibility: hidden;
    display: none;
}
#OmGridInfo td{
    padding:0 8px;
    height:25px;
    border: 1px solid #e0e0e0!important;
    text-align: left !important;
}
#OmGridInfo .dx-header-row {
    height: 25px;
    text-align: center;
    width: 100%;
    font-size: 1.3rem;
}

#sujuEditPopup input{
    padding: 1rem !important;
    line-height:3rem;
}
#small,
#medium,
#large  {
    display: none !important;
}
.dx-datagrid .dx-column-indicators {
    float: left !important;
    padding-right: 5px;
}
#SUJU_View .dx-text-content-alignment-right,
#SUJU_View .dx-text-content-alignment-left {
    text-align: left;
}

#SUJU_View .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
    vertical-align: middle;
}
#SUJU_View .dx-header-row .dx-header-filter-indicator.dx-text-content-alignment-left, 
#SUJU_View .dx-header-row .dx-sort-indicator.dx-text-content-alignment-left {
    margin-right: 0;
}
#SUJU_View .dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
    white-space: nowrap;
    font-size: 1.2rem;
}

#SUJU_View .p-highlight {
    background:#fff !important;
    color:#787878;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}
#SUJU_View .p-selectbutton .p-button {
    background:#f8f8f8;
}
#SUJU_View {
    overflow: auto;
}
#OmGrid .dx-datagrid-header-panel {
    display:none !important
}
#OmGridInfo .dx-datagrid-header-panel {
    display:none !important
}
</style>