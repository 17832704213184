<template>
    <div id="OutputYearChart" class="grid grid-rows-12 border-[1px] p-[1.6rem] rounded-[1.4rem] shadow-lg font-NotoSansKR min-w-[38rem] max-w-[140rem]">
        <div class="flex flex-row justify-between items-start font-semibold px-[1.3rem] mb-[0.6rem] w-full">
            <div class="flex flex-row justify-start w-full">
                <h1 class="text-[1.6rem] w-[10rem]">연간 실적</h1>
                <p class="w-full text-start text-[1rem] text-[#92959B] leading-[2rem] font-semibold">(단위 : 억원)</p>
            </div>
        </div>
        <div class="flex flex-row items-center text-[1.1rem] px-4 mb-[1rem]">
            <div class="bg-[#E2E2E2] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-lg mr-[0.4rem]"></div>
            <span>목표</span>
            <div class="bg-[#EC77A1] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
            <span>수주</span>
            <div class="bg-[#5BA9E1] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
            <span>수금</span>
            <div class="bg-[#EFBB6F] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
            <span>영업이익</span>
        </div>
        <Bar :options="chartOptions" :data="chartData" ref="barChart" height="200" v-if="loaded" :plugins="[ChartJSPluginDatalabels]"/>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import RestHelper from '../../../Helpers/RestHelper';
import LogHelper from '../../../Helpers/LogHelper';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    name: "OutputYearChart",
    components: {
        Bar,
    },
    props: {
        year: {
            type: Number,
            required: true
        },
        month: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            loaded: false,
            // object 형태로 설정
            originalData: {
                target: [],
                actual: []
            },
            chartData: {
                labels: ['수주', '수금', '영업이익'],
                datasets: [
                    {
                        label: '목표',
                        data: [],
                        backgroundColor: [],
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    },
                    {
                        label: '실적',
                        data: [],
                        backgroundColor: [],
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                borderRadius: 8,
                layout: {
                    autoPadding: false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        display: false,
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        mode: 'x',
                        intersect: false,
                        // tootip 관련 설정
                        callbacks: {
                            label: function (tooltipItem) {
                                const datasetIndex = tooltipItem.datasetIndex;
                                const dataIndex = tooltipItem.dataIndex;

                                let originalValue;
                                let label;
                                
                                if (datasetIndex === 0) {
                                    originalValue = this.originalData.target[dataIndex];
                                    label = '목표';
                                } else {
                                    originalValue = this.originalData.actual[dataIndex];
                                    label = '실적';
                                }
                                return `${label} : ${originalValue.toFixed(1)}`; // 소숫점 1자리까지 표기
                            }.bind(this)
                        }
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: function(value, context) {
                            let originalValue = context.datasetIndex === 0 ? this.originalData.target[context.dataIndex] : this.originalData.actual[context.dataIndex];
                            return originalValue < 0 ? `${Number(originalValue).toFixed(1)}` : `${Number(originalValue).toFixed(1)}`;
                        }.bind(this)
                    }
                },
            },
        }
    },
    async mounted() {
        await this.get();
    },
    watch: {
        async year() {
            await this.get();
        },
        async month() {
            await this.get();
        },
        chartData(newData) {
            if (this.$refs.barChart && this.$refs.barChart.chart) {
                this.$refs.barChart.chart.destroy();
                this.$refs.barChart.renderChart(newData, { responsive: true });
            }
        },
    },
    computed: {
        ChartJSPluginDatalabels()
        {
            return ChartJSPluginDatalabels;
        },
        barChart()
        {
            return this.$refs.bar.chart;
        },
        chartData()
        {
            return this.chartData;
        },
        chartOptions()
        {
            return this.chartOptions;
        },
    },
    methods: {
        async get() {
            this.loaded = false;
            try {
                const res = await RestHelper.RestSend('USP_BORD', 'BORD1', [{ BORD_YEAR_CN: this.year, BORD_MONTH_CN: this.month }]);
                LogHelper.logWrite('연간실적 데이터 요청', [res], this.logUse);
                if (res._result[0].RESULTCD == '1') {
                    const data = [...res._data02];
                    this.originalData = {
                        target: [...data.map(row => row.수주계획), ...data.map(row => row.수금계획), ...data.map(row => row.영업이익계획)],
                        actual: [...data.map(row => row.수주실적), ...data.map(row => row.수금실적), ...data.map(row => row.영업이익실적)]
                    };
                    this.chartData.datasets[0].data = [...data.map(row => this.getNumber(row.수주계획)), ...data.map(row => this.getNumber(row.수금계획)), ...data.map(row => this.getNumber(row.영업이익계획))];
                    this.chartData.datasets[1].data = [...data.map(row => this.getNumber(row.수주실적)), ...data.map(row => this.getNumber(row.수금실적)), ...data.map(row => this.getNumber(row.영업이익실적))];

                    this.loaded = true;
                    this.updateChart(data); 
                }
            } catch (error) {
                console.error("연간실적 데이터 가져오기 실패:", error);
            }
            // 차트 유동적으로 조정~..
            this.$nextTick(() => {
                const tempOption = {...this.chartOptions};
                tempOption.scales = { ...tempOption.scales };  // 반응성을 위한 복사
                tempOption.scales.y = { ...tempOption.scales.y };  // 반응성을 위한 복사
                tempOption.scales.y.max = Math.max(...this.chartData.datasets.map(x => Math.max(...x.data))) * 1.3;
                this.chartOptions = { ...tempOption };
            });
        },
        getNumber(str) {
            let unit = 10;
            let value = this.roundTo(unit, Number(str));
            return value < 0 ? 0 : Math.max(value, 0);
        },
        roundTo(unit, num) {
            return Math.round(num * unit) / unit;
        },
        updateChart(data) {
            // 데이터 초기화
            this.chartData.datasets.forEach(dataset => {
                dataset.data = [];
                dataset.backgroundColor = [];
            });

            // Original data 초기화
            this.originalData.target = [];
            this.originalData.actual = [];

            // 데이터 추가
            data.forEach(row => {
                // Original data 저장
                this.originalData.target.push(row.수주계획, row.수금계획, row.영업이익계획);
                this.originalData.actual.push(row.수주실적, row.수금실적, row.영업이익실적);

                // 목표 데이터 추가
                this.chartData.datasets[0].data.push(this.getNumber(row.수주계획));
                this.chartData.datasets[0].backgroundColor.push('#E2E2E2');

                this.chartData.datasets[0].data.push(this.getNumber(row.수금계획));
                this.chartData.datasets[0].backgroundColor.push('#E2E2E2');

                this.chartData.datasets[0].data.push(this.getNumber(row.영업이익계획));
                this.chartData.datasets[0].backgroundColor.push('#E2E2E2');

                // 실적 데이터 추가
                this.chartData.datasets[1].data.push(this.getNumber(row.수주실적));
                this.chartData.datasets[1].backgroundColor.push('#EC77A1');

                this.chartData.datasets[1].data.push(this.getNumber(row.수금실적));
                this.chartData.datasets[1].backgroundColor.push('#5BA9E1');

                this.chartData.datasets[1].data.push(this.getNumber(row.영업이익실적));
                this.chartData.datasets[1].backgroundColor.push('#EFBB6F');
            });

            // 차트 업데이트
            this.$nextTick(() => {
                if (this.$refs.barChart && this.$refs.barChart.chart) {
                    this.$refs.barChart.chart.update();
                }
            });
        }
    },
};
</script>

<style scoped>
#OutputYearChart canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 600px !important;
    max-height: 200px !important;
}
</style>
